import React, { Component } from "react";
import appinfo from "../../clientsJSON";

class Seo extends Component {
    render () {
        if(appinfo.seo.show) {
            return(
                <div style={{display: "flex", flexDirection: "column", textAlign: "center",backgroundColor:"#fff"}}> 
                    <h2>Popular Search</h2>
                    <div style={{flexDirection: "row", paddingLeft: 40}}>
                    {
                        appinfo.seo.seo.map( e => {
                            return (
                                <a href={"#"+e.replace(" ","-")} alt={e} style={{textDecoration:"none",color:appinfo.secondaryTextColor}}>
                                    {e + "  |  "}
                                </a>
                            )
                        })
                    }
                    </div>
                </div>);
        } else return <div></div>;
    }
}

export default Seo;