import React, { Component } from "react";
import { 
    ContentLayout, 
    Layout, 
    SingleImageLayout, 
    Link
} from "../";

export default class BlogCard extends Component {
    
    render(){
        const {
            containerProps = {},
            imageProps = {},
            linkProps = {},
            contentProps = {}
        } =  this.props;
        
        return(
            <Layout 
                columns={containerProps.columns || 3} 
                extraClasses={containerProps.extraClasses || "center space"} 
                layoutStyle={containerProps.layoutStyle || {}}
            >
                <Link  
                    link = {linkProps.link || "#"} 
                    newTab = { linkProps.newTab || false }
                >
                    { !["", undefined, null].includes(imageProps.imageSource) &&
                        <SingleImageLayout 
                            imageSource={imageProps.imageSource || ""} 
                            imageStyle={imageProps.imageStyle || {}} 
                            imageWidth={imageProps.imageWidth || "100%"} 
                            imageHeight={imageProps.imageHeight || "100%"}
                            imageAlt={imageProps.imageAlt || ""}
                            imageContainerStyle={imageProps.imageContainerStyle || {}}
                            extraClasses = {imageProps.extraClasses || ""}
                        />
                    }
                    { 
                        (!["", undefined, null].includes(contentProps.description) ||
                        !["", undefined, null].includes(contentProps.title) ) &&
                        <ContentLayout
                            title={contentProps.title || ""}
                            description={contentProps.description || ""}
                            titleStyle={contentProps.titleStyle || {}}
                            subTitle={contentProps.subTitle || ""}
                            subTitleStyle={contentProps.subTitleStyle || {}}
                            descriptionStyle={contentProps.descriptionStyle || {}}
                            contentStyle={contentProps.contentStyle || {}}
                            extraClasses={contentProps.extraClasses || ""}
                            tag = {{ title: "h2", subTitle: "h4" , ...contentProps.tag }}
                        />
                    }
                </Link>
            </Layout>
        );
    }
}