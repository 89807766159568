import React, { Component } from "react";
import {
    SingleImageLayout,
    NituWidget
} from "..";
import appinfo from "../../clientsJSON";

export default class BannerTemplate3 extends Component {
    render(){
        const { 
            showWidget = true,
            widgetProps = {},
            container = {}
        } = this.props;
        
        let width = window.screen.width >  768 ? window.screen.width / 2 : window.screen.width;
        let mobile = window.screen.width <  768 ;
        
        return(
            <div className="banner-container-home" 
                style={{    
                            flexDirection: mobile ? "column" : 
                            ( container.container_reverse ? "row" : "row-reverse"),
                            backgroundColor: appinfo.background_color
                        }}>
                <div className="banner-container-left-home" 
                    style={{minWidth: width, minHeight: "600px"}}>
                    {   showWidget &&
                        <NituWidget
                            clientId = {appinfo["x-access-client-id"]}
                            formId = {appinfo["form"]}
                            width = { widgetProps.width || "100%" }
                            height = { widgetProps.height ||  "400px" }
                            style = { widgetProps.style || {}}
                        />
                    }
                </div>
                <div className="banner-image center">
                    <SingleImageLayout
                        imageSource = {container.image_path}
                        imageAlt= {"Hero Banner"}
                        imageWidth = {width }
                        imageHeight = {"600px" }
                    />
                </div>
            </div>
        );
    }
}