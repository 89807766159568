export const authenticationConstants ={
    SENDINGOTP_REQUEST:"SENDINGOTP_REQUEST",
    SENDINGOTP_SUCCESS:"SENDINGOTP_SUCCESS",
    SENDINGOTP_ERROR:"SENDINGOTP_ERROR",

    LOGIN_REQUEST:"LOGINVERIFY_REQUEST",
    LOGIN_SUCCESS:"LOGINVERIFY_SUCCESS",
    LOGIN_ERROR:"LOGINVERIFY_ERROR",

    SIGNUP_REQUEST:"SIGNUP_REQUEST",
    SIGNUP_SUCCESS:"SIGNUP_SUCCESS",
    SIGNUP_ERROR:"SIGNUP_ERROR",

    ISAVAILABLE_REQUEST:"ISAVAILABLE_REQUEST",
    ISAVAILABLE_SUCCESS:"ISAVAILABLE_SUCCESS",
    ISAVAILABLE_ERROR:"ISAVAILABLE_ERROR",

    LOGOUT: "USERS_LOGOUT"
}