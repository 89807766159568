import React, { Component } from "react";
import {
    ContentLayout,
    Layout,
    Link,
    SingleImageLayout
} from "..";

class MediaCard extends Component {

    renderHoverLayout = (imageProps = {}, contentProps = {}) => {
        return (
            <div className="media-team" style={{ borderRadius: "12px" }}>
                { !["", undefined, null].includes(imageProps.imageSource) &&
                    <SingleImageLayout 
                        imageSource={imageProps.imageSource || ""} 
                        imageStyle={imageProps.imageStyle || {}} 
                        imageWidth={imageProps.imageWidth || "100%"} 
                        imageHeight={imageProps.imageHeight || "100%"}
                        imageAlt={imageProps.imageAlt || ""}
                        imageContainerStyle={imageProps.imageContainerStyle || {}}
                        extraClasses = {imageProps.extraClasses || ""}
                    />
                }

                <div className="media-text">
                    { 
                        (!["", undefined, null].includes(contentProps.subTitle) ||
                        !["", undefined, null].includes(contentProps.title) ) &&
                        <ContentLayout
                            description = { contentProps.description || ""}
                            descriptionStyle={{ color: "#fff" , ...contentProps.descriptionStyle }}
                            contentStyle = {{ "padding" : "0px 20px", "borderRadius": "12px" }}
                        />
                    }
                </div>

            </div>
        )
    }

    render(){
        const {
            containerProps = {},
            imageProps = {},
            linkProps = {},
            contentProps = {}
        } =  this.props;
        return(
            <Layout 
                columns={containerProps.columns || 3} 
                extraClasses={containerProps.extraClasses || "center space"} 
                layoutStyle={{ borderRadius: "12px" , ...containerProps.layoutStyle }}
            >
                <Link  
                    link = {linkProps.link || "#"} 
                    newTab = { linkProps.newTab || false }
                >
                {
                    this.renderHoverLayout ( imageProps, contentProps )
                }
                { 
                    (!["", undefined, null].includes(contentProps.subTitle) ||
                    !["", undefined, null].includes(contentProps.title) ) &&
                    <ContentLayout
                        title={contentProps.title || ""}
                        titleStyle={contentProps.titleStyle || {}}
                        subTitle={contentProps.subTitle || ""}
                        subTitleStyle={contentProps.subTitleStyle || {}}
                        contentStyle={contentProps.contentStyle || {}}
                        extraClasses={contentProps.extraClasses || ""}
                        tag = {{ title: "h2", subTitle: "h4" , ...contentProps.tag }}
                    />
                }
                { 
                    !["", undefined, null].includes(linkProps.link) &&
                    !["", undefined, null].includes(linkProps.linkText) &&
                    <Link
                        linkStyle = {linkProps.linkStyle || {}}
                        link = {linkProps.link || ""}
                        linkText = { linkProps.linkText || ""}
                        showIcon = {linkProps.showIcon || false }
                        iconName = {linkProps.iconName || ""}
                        iconReverse = { linkProps.iconReverse || false } 
                        iconStyle = { linkProps.iconStyle || {}}
                        newTab = { linkProps.newTab || false }
                        extraClasses = { "flex center padding-20 " + linkProps.extraClasses }
                    />
                }
                </Link>
            </Layout>
        );
    }
}
export default MediaCard;