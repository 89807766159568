import appinfo from "../clientsJSON";
import Store from "../reducers";
import {httpError} from "./httperror";


export const httpClient = {
    deleteReq,
    get,
    getHeaders,
    callApi,
    handleResponse,
    post,
    postImage,
}

function getHeaders(){

    let user = Store.getState().authentication.user;

    let headers={
        "Accept":"application/json",
        "Content-Type":"application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-client-id": appinfo["x-access-client-id"]
    }

    // if(typeof user != "undefined"){
    //   headers["x-access-token"] = user["token"];
    // }


    return {headers};
}

function getRequestOption(credentials){
  let headers = getHeaders(credentials).headers;
  let requestOptions ={
    credentials: "include",
    headers
  };

  if(!credentials){
    delete requestOptions["credentials"];
  }

  return requestOptions;
}
function get(credentials = false){
    let requestOptions = getRequestOption(credentials);
    requestOptions["method"] ="GET";
    return requestOptions;
}

function deleteReq(credentials = false) {
    let requestOptions = getRequestOption(credentials);

    requestOptions["method"] ="DELETE";
    return requestOptions;

}

function post(payload, credentials = false){
    let requestOptions = getRequestOption(credentials);

    requestOptions["method"] ="POST";
    requestOptions["body"] =JSON.stringify(payload);
    return requestOptions;
}

function postImage(payload){
    let requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        body: payload
      };
    
      return requestOptions;
}


function callApi(
    method,
    endpoint,
    payload = {},
    credentials = false,
    image = false
  ) {
    let requestOptions = {};
    let url = appinfo.url;
  
    switch (method) {
      case "GET":
        requestOptions = get(credentials);
        break;
      case "POST":
        if (image) {
          requestOptions = postImage(payload);
        } else {
          requestOptions = post(payload, credentials);
        }
        break;
      case "DELETE":
        requestOptions = deleteReq(credentials);
        break;
      default:
        console.log("Nothing here");
    }

    return fetch(`${url}${endpoint}`, requestOptions).then(handleResponse);
  }
  

  function handleResponse(response) {
    let json = response.json();
    if (!(response.status === 200 || response.status === 201)) {
      return json.then(res => {
        return Promise.reject(res);
      });
    }
  
    return json;
  }
  