import React, { Component } from "react";
import { NituWidget } from "..";
import appinfo from "../../clientsJSON";

export default class BannerTemplate2 extends Component {
    render(){
        const { 
            style = {},
            showWidget = true,
            widgetProps = {}    
        } = this.props;
        
        return(
            <div className="mp-container banner" 
                style={style}
            >
                <div className="mp-card">
                    { showWidget &&
                        <NituWidget
                            clientId = {appinfo["x-access-client-id"]}
                            formId = {appinfo["form"]}
                            width = { widgetProps.width || "100%" }
                            height = { widgetProps.height ||  "400px" }
                            style = { widgetProps.style || {}}
                        />
                    }
                </div>
            </div>
        );
    }
}