import React,{Component} from "react";
import "./index.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import appinfo from "../../clientsJSON";
class SectionCard extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        const { content={}, customStyle={}, index=0, type=[] } = this.props;
        let link
           if(appinfo.template==1){
                link=content.title ? `/`+appinfo.services.title+`/`+content.title : "#"
           }
           else {
               link="#"
           }
        return (
            <a href={link} alt={content.title} style={{textDecoration:"none"}} className="section1">
            <div
                // onClick={()=>this.nextpage(content.title)}
                style={{backgroundColor: index%2 ===0 ? "white": "#F6F6F6"}}>
            
                {type.includes(1) && <img src={content.image || ""} style={{width:336,height:200}} alt={content.title}/>}
                {type.includes(2) && appinfo.home_sections[index].iconshow && <img src={content.icon} alt={content.title} style={{marginLeft: "auto", marginRight: "auto", display: "flex",backgroundColor: "transparent"}}/>}
                {type.includes(2) && <h3 style={{margin:0, textDecoration: "none",color:appinfo.primaryTextColor}}>{content.title || ""}</h3>}             
                {type.includes(3) && <p style={{padding:"0px 8px",textAlign:"justify",margin:0}}>&nbsp;&nbsp;{content.description || ""}</p>}
                {appinfo.template===1&&<a href={link} target="_blank" style={{textDecoration:"none",color:appinfo.button_color}}><button style={{backgroundColor:"white",color:appinfo.button_color,margin:10,border:"none"}}className="button" >VIEW {content.title || ""}<i class="material-icons" style={{verticalAlign:"sub",height:16,fontSize:"large"}}>arrow_forward</i></button></a>}
            </div>
            </a>
        );
    }
}
function mapStateToProps(state) {
    const { endpoint } = state;
    return {
        endpoint
    };
  }
  
  export default withRouter(connect(mapStateToProps)(SectionCard));
