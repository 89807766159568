import React, { Component } from "react";
import {
    ContentLayout,
    Layout,
    Link,
    SingleImageLayout
} from "..";

export default class TeamCard extends Component {

    renderHoverLayout = (imageProps = {}, contentProps = {}) => {

        return (
            <div className="team">
                { !["", undefined, null].includes(imageProps.imageSource) &&
                    <SingleImageLayout 
                        imageSource={imageProps.imageSource || ""} 
                        imageStyle={imageProps.imageStyle || {}} 
                        imageWidth={imageProps.imageWidth || "260px"} 
                        imageHeight={imageProps.imageHeight || "auto"}
                        imageAlt={imageProps.imageAlt || "team"}
                        imageContainerStyle={{ margin: "0px" , ...imageProps.imageContainerStyle }}
                        extraClasses = {imageProps.extraClasses || ""}
                    />
                }

                <div className="team-text">
                    { 
                        (!["", undefined, null].includes(contentProps.subTitle) ||
                        !["", undefined, null].includes(contentProps.title) ) &&
                        <ContentLayout
                            title={contentProps.title || ""}
                            titleStyle={{ color: "#fff", ...contentProps.titleStyle }}
                            subTitle={contentProps.subTitle || ""}
                            subTitleStyle={{ color: "#fff", ...contentProps.subTitleStyle }}
                            description={contentProps.description || ""}
                            descriptionStyle={{ color: "#fff", ...contentProps.descriptionStyle }}
                            contentStyle={contentProps.contentStyle || {}}
                            extraClasses={contentProps.extraClasses || ""}
                            tag = {{ title: "h5", subTitle: "p" , ...contentProps.tag}}
                        />
                    }
                    <div className="flex wrap" style={{ padding: "20px"}}>
                        {   contentProps.socialIcons !== undefined &&
                            contentProps.socialIcons.length !== 0 &&
                            contentProps.socialIcons.map( i => {
                                return <Link
                                            linkStyle = { i.linkStyle || {} }
                                            link = { i.link || "" } 
                                            showIcon = { true } 
                                            newTab = { i.newTab || true }
                                            iconReverse = { true }
                                            extraClasses = { i.extraClasses || "icon left" }
                                            iconStyle = {{ color: "#fff" , ...i.iconStyle }}
                                            iconExtraClasses = { i.iconName || "" }
                                        />
                            })
                        }
                     </div>
                </div>
            </div>
        )
    }

    render(){
        const {
            containerProps = {},
            imageProps = {},
            contentProps = {}
        } =  this.props;

        return(
            <Layout 
                columns={containerProps.columns || 3} 
                extraClasses={containerProps.extraClasses || "space"} 
                layoutStyle={{ ...containerProps.layoutStyle }}
            >
                {
                    this.renderHoverLayout ( imageProps, contentProps )
                }
                { 
                    (!["", undefined, null].includes(contentProps.subTitle) ||
                    !["", undefined, null].includes(contentProps.title) ) &&
                    <ContentLayout
                        title={contentProps.title || ""}
                        titleStyle={contentProps.titleStyle || {}}
                        subTitle={contentProps.subTitle || ""}
                        subTitleStyle={contentProps.subTitleStyle || {}}
                        contentStyle={contentProps.contentStyle || {}}
                        extraClasses={contentProps.extraClasses || ""}
                        tag = {{ title: "h3", subTitle: "p" , description : "p", ...contentProps.tag }}
                    />
                }                           
            </Layout>
        );
    }
}