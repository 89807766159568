import React from 'react';
import { utils } from '../../utils';

import appinfo from "../../clientsJSON";
import "./index.css";



const FooterTemplate2 = props => {
    let content = props.content || {};
    
    return (
    <div className="footerBackground" style={{backgroundColor : appinfo.primary_color}}>
        <div className="container footer-content"  >
            <ul>
                <li>
                    <a style={{display:"flex",justifyContent:"center"}} href="/"><img className="logo" src={appinfo.logo_white} alt={appinfo.name}/></a>
                </li>
                <li>
                    <h3 className="bold" style={{textAlign:"left",color:"#FFF"}}>Contact Us</h3>
                    {   appinfo.mobile.map(
                        (i, index) => {
                            return (
                                <p style={{textAlign:"left", maxWidth:300,color:appinfo.secondary_color,fontSize:14}}>
                                    <a href= {utils.getLink("call", index)} className="bold" style={{color:appinfo.secondary_color}}>
                                        <span class="material-icons" style={{fontSize:15}} >phone</span> {i}
                                    </a>
                                </p>)
                        })
                    }
                    {
                        appinfo.email.map(
                            (i, index) => {
                                return (
                                    <p style={{textAlign:"left", maxWidth:300,color:appinfo.secondary_color,fontSize:14}}>
                                        <a href= {utils.getLink("email", index)} className="bold" style={{color:appinfo.secondary_color}}>
                                            <span class="material-icons" style={{fontSize:15}}>mail_outline</span> {i}
                                        </a>
                                    </p>
                                )
                            }
                        )
                    }
                    <h3 className="bold" style={{textAlign:"left",color:"#FFF"}}>Address</h3>
                    <p style={{textAlign:"left", maxWidth:300,color:appinfo.secondary_color,fontSize:14}}><span class="material-icons" style={{fontSize:15}}>business</span> {content.address[0].address}</p>
                </li>
                <li>
                    {
                        content.menu.length !== 0 &&
                        content.menu.map(
                            i => {
                                return (
                                    <p>
                                        <a href={i.link} className="bold" style={{color:appinfo.secondary_color}} >
                                            {i.value}
                                        </a>
                                    </p>
                                )
                            }
                        )
                    }
                </li>
                <li>
                    <p><a href="/terms-and-conditions" className="bold" style={{color:appinfo.secondary_color}}>Terms and Conditions</a></p>
                    <p><a href="/privacy-policy" className="bold" style={{color:appinfo.secondary_color}}>Privacy Policy</a></p>
                    <p><a href="/refund-policy" className="bold" style={{color:appinfo.secondary_color}}>Refund/Return Policy</a></p>
                </li>
            </ul>
        </div>
        {/* <MayIHelpYou/> */}
    </div>
)
}
export default FooterTemplate2;