import React, { Component } from "react";
import { 
    ContentLayout, 
    Layout,
    ListViewLayout
} from "..";

export default class ListSection extends Component{

    render(){
        const {  
            contentProps = [], 
            headerProps = {}, 
            backgroundColor = "transparent",
            sectionStyle = {}
        } = this.props;


        return(
        <Layout
            layoutStyle={{backgroundColor, ...sectionStyle }}
            extraClasses = "no-radius"
        >
            <div className="container">
                <ContentLayout
                    title={headerProps.title || ""}
                    description={headerProps.description || ""}
                    titleStyle={headerProps.titleStyle || {}}
                    descriptionStyle={headerProps.descriptionStyle || {}}
                    subTitle={headerProps.subTitle || ""}
                    subTitleStyle={headerProps.subTitleStyle || {}}
                    contentStyle={headerProps.contentStyle || {}}
                    extraClasses={headerProps.extraClasses || ""}
                    tag = {{ title: "h2", subTitle: "h4", ...headerProps.tag  }}
                />
                <div className="flex wrap">
                    { 
                        contentProps.map( content => <>{
                            <ListViewLayout
                                title = {content.title || ""}
                                titleStyle = { content.titleStyle || {}}
                                listText = { content.listText || []}
                                listStyle = { content.listStyle || {}}
                                containerProps = { content.containerProps || {}}
                                tag = { content.tag || { title: "h3"}}
                            />
                        }</>)
                    }
                </div>
            </div>
        </Layout>
        );
    }
}