import {blogConstants} from "../constants";

const initialState ={
    blogs:[]
};

export function blog(state =initialState,action){
    switch(action.type){
        case blogConstants.ALLBLOG_REQUEST:
            return{
                ...state,
                status:"GET_ALLBLOG_REQUEST"
            };
        case blogConstants.ALLBLOG_SUCCESS:
            return {
                ...state,
                status:"GET_ALLBLOG_SUCCESS",
                blogs:action.message
            };
        case blogConstants.ALLBLOG_ERROR:
            return {
                ...state,
                status:"GET_ALLBLOG_ERROR",
                blogs:action.error
            };
        case blogConstants.VIEW_REQUEST:
            return {
                ...state,
                status:"GET_VIEW_REQUEST"
            };
        case blogConstants.VIEW_SUCCESS:
            return {
                ...state,
                status:"GET_VIEW_SUCCESS",
                blog:action.message
            };
        case blogConstants.VIEW_ERROR:
            return {
                ...state,
                status:"GET_VIEW_ERRROR",
                blogs:action.error
            };
        default:
            return state;
    }
}