import React, { Component } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default class BannerTemplate1 extends Component {
    render(){
        const { carousel_images = [] } = this.props;

        return(
            <Carousel 
                autoPlay 
                showStatus={false} 
                scroll_duration={0.1} 
                infiniteLoop 
                showThumbs={false}
            >
               {
                    carousel_images.map(i=>{
                        return(<img src={i} alt="image"/> )
                    })
                }
            </Carousel>
        );
    }
}