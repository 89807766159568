import React, { Component } from "react";

export default class ExternalUrlLayout extends Component {
    
    render(){
        const {
            source = "",
            width = "100%",
            height = "400px",
            style = {},
            allowFullScreen = true
        } = this.props;

        return(
                <iframe 
                    src = {source} 
                    width = {width || "100%"} 
                    height = {height || "100%" } 
                    frameborder={0} 
                    style={ style || { border:0 }}
                    allowfullscreen = { allowFullScreen || true}
                />
        )
    }
}