import React,{Component} from "react";
import  SectionCard  from "./sectioncard.js";


class Sections extends Component {

    render(){
        const { content={}, customStyle={}, index=0 } = this.props;

        return (
            <div style={{display:"flex",flexWrap:"wrap",width:"90%",justifyContent:"space-around",margin:"auto"}}>
                { 
                    content.cards.map( single => {
                        return   <SectionCard 
                                    content = {single} 
                                    customStyle = {customStyle} 
                                    index={index}
                                    type={content.type}
                                />;    
                        }
                    )
                }
            </div>
        );
       
    }
}
export default Sections;