import React, { Component } from "react";
import "./index.scss"
import { TimelineCard } from "../";

class Timeline extends Component {

  render() {

    const { 
        containerProps = {},
        contentProps = [],
        buttonProps= {}
    } = this.props;

    return (
        <div className="timeline" id="timeline">
          <div className="container">
            {contentProps.map(contentProp => {
                return  <TimelineCard 
                          contentProp = { contentProp || {} }
                          buttonProps = { buttonProps || {} }
                          containerProps = { containerProps || {} }
                        />
            })}
          </div>
        </div>
    )
  }
}
export default Timeline; 