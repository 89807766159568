import React,{Component} from 'react';
import "./index.scss";
import appinfo from "../../clientsJSON";
import ReactMarkdown from "react-markdown"


class SingleBlog extends Component{
    
  render(){
      const {content ={}}=this.props;
      return(
        <div>
        <div className="mpblogheader" style={{backgroundImage:`url(${content.image})`}}>
        <div className="mpcontainerblog">
            <div className="mpblognav">
                <div className="mplogoblog">
    
                </div>
                {/* <div className="mpblogsubscribe">
                    <a  href="#">Follow us</a>
                </div> */}
            </div>
            <blockquote className="mpblogblockquote" >{content.description}
            <span>&mdash;{appinfo.name}</span>
            </blockquote>
            {/* <div className="dateblog">
                <i className="fa fa-calendar" aria-hidden="true"></i> 
                {content.modified}
            </div> */}
        </div>
    </div>
    
    <div  className="mpblogsection">
        <div className="scroll">
            <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
        </div>
        <div className="mpcontainerblog">
            <h1 className="mpblogh1">{content.title}</h1>
            <div className="byline">
                <div className="mpblogavatar" style={{backgroundImage:`url(${appinfo.logo})`}}></div>
      by <span>{appinfo.name}</span>
            </div>
            <ReactMarkdown source={content.content} rawSourcePos={true}/>
            <p><strong>The End!</strong></p>
        </div>
    </div>
       {content.tags.length>0 &&<div className="mpcontainerblog">
            
            <ul className="mpblogtags">
                <li className="mpblogtag">Tags</li>
        {content.tags.map(i=>{return(<li className="mpbloglist"><a className="mpblogatag" href="#">{i.name}</a></li>)})}
                
            </ul>
        </div>}
    </div>
      )
  }
}

export default SingleBlog;