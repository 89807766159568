import React, { Component } from "react";
import appinfo from "../../clientsJSON";
import play from "../../images/Bitmap.svg"
import "./index.scss";

class SingleProduct extends Component {

  imageFunction(smallImage, video) {
    let fullImg = document.getElementById("imageBox");
    fullImg.src = smallImage
  }
  render() {
    let source = ""
    source = `https://development.moonproduct.in?id=${appinfo["x-access-client-id"]}&form=${appinfo["form_product"]}`
    const { content = {}, index = 0 } = this.props;
    return (
      <div className="container">
        <h1 style={{ color: appinfo.primaryTextColor, paddingTop: 50, paddingLeft: 10 }}>{content.title}</h1>
        <div>
          <div className="productcontain">
            <div className="product">
              <div className="product-small-img">
                {content.images !== "undefined" && content.images.map(i => {
                  return (<img src={i} onClick={(e) => this.imageFunction(i)} />)
                })}
              </div>
              <div className="img-container">
                <img id="imageBox" src={content.images[0]} />
                {content.waranty !== "undefined" && <p style={{ textAlign: "center", fontWeight: "bold", color: "#444444" }}>{content.waranty}</p>}
              </div>
            </div>
            <iframe src={source} frameBorder="0" width="550px" height="480px" ></iframe>
          </div>
          <hr></hr>
        </div>
        {/* Award Section */}
        {content.awardshow &&
          <div className="awards">
            {content.awards.map(i => {
              return (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img src={i.icon} style={{ float: "left" }} style={{ margin: "20px" }} />
                  <div>
                    <h2> {i.title}</h2>
                    <p>{i.short_description}</p>
                  </div>
                </div>
              )
            })}
            <hr></hr>
          </div>
        }
        {/* Main content of product */}
        {/* <div class="productdescription" id="description">
           <div class="productdescription__img">
              <img src={content.image}/>
            </div>
            <div class="description__details">
             <div class="details-container">
             <h1 style={{color:appinfo.primaryTextColor}}>{content.title}</h1>
              <h2>Description</h2>
           <p class="intro">{content.description}</p>
          </div>
        </div>
       </div> */}
        {/* Product Video section */}
        {/* {content.productvideo_show && 
         <div>
             <h1  style={{textAlign:"center",fontSize:"bold",color:appinfo.primaryTextColor}}>Product Video</h1>
                 <div class="product-demo" id="demo">
                  <div class="product-demo__video">
                       <div class="product-demo__wrapper">
                       <iframe width="560" height="315" src={content.productvideo_url} frameborder="0" allow="accelerometer;encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                       </div>
                    </div>
               <p class="product-demo__caption">{content.title}</p>
            </div>
         </div>
        } */}
        {/* Product Description */}
        <div style={{ padding: 10 }}>
          <h2 style={{ paddingLeft: 20 }}>Product Description</h2>
          <p class="intro" style={{ textAlign: "justify", padding: "0px 20px 0px 20px" }}>{content.description}</p>
        </div>
        {/* Points Method of Product */}

        {content.pointmethod_show &&
          <div>
            <hr></hr>
            <div className="Points">
              {content.pointcontent.map(i => {
                return (
                  <div className="features" id="features">
                    <div class="features__list">
                      <h2>{i.title}</h2>
                      <ul>
                        {i.content.map(j => { return (<li>{j}</li>) })}
                      </ul>
                    </div>
                  </div>
                )
              })}</div>
          </div>}


        {/* Table type of products */}
        {content.tabletype_show && content.tablecontent.map(i => {
          return (
            <div>
              <hr></hr>
              <h1 style={{ textAlign: "center" }}>{i.title}</h1>
              <table>
                <thead>
                  <tr>
                    {i.tablehead.map(j => {
                      return <th>{j}</th>
                    })}
                  </tr>
                </thead>
                <tbody>
                  {i.innercontent.map(k => {
                    return (
                      <tr>
                        {k.data.map((j, index) => { return (<td data-column={i.tablehead[index]}>{j}</td>) })}
                      </tr>)
                  })}
                </tbody>
              </table>
            </div>
          )
        })}
      </div>
    )
  }
}
export default SingleProduct;
