import { httpClient } from "../utils";

export const blogService ={
    Getallblog,
    viewblog,
}

function Getallblog( page = 1 ){
    return httpClient.callApi("GET",`/blog/posts/${page}`,{},false)
}

function viewblog(url){
    return httpClient.callApi("GET",`/blog/${url}`)
}