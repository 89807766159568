import React,{Component} from "react";
import appinfo from "../../clientsJSON";
// import "./index.css";
import Menu from "../../component/Menu";
import Footer from "../../component/footer";

;

class Terms extends Component {
    render(){
        document.title = "Terms and Conditions | " + appinfo.name;
        return (
            <div>
                <Menu/>
                <div className="mp-container" style={{textAlign:"center",padding:50}}>
                <h1 style={{textTransform:"uppercase"}}>{appinfo.policies.terms.title}</h1>
                    <p>{appinfo.policies.terms.description}</p>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default Terms;