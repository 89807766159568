import React, { Component } from "react";
import { IconLayout, Link } from "../";
import appinfo from "../../clientsJSON";

export default class Button extends Component {
    render(){
        const { 
            buttonText = "" , 
            buttonStyle = {},
            extraClasses = "",
            showIcon = false,
            iconName = "",
            iconReverse = false,
            iconStyle = {},
            buttonLink = "",
            newTab = false
        } = this.props;

        return (
            <Link
                    link = {buttonLink}
                    newTab = {newTab}
                >
                <button className={"button-default "+extraClasses} 
                    style={ { 
                        marginTop: "25px",
                        backgroundColor: appinfo.button_color, 
                        ...buttonStyle
                    }}
                >
                    {buttonText + " "}
                    { 
                        showIcon &&
                        <i className="material-icons" 
                            style={{
                                verticalAlign: "sub", 
                                height: "16px",
                                paddingLeft : iconReverse ? "0px" : "10px",
                                paddingRight: !iconReverse ? "0px" :"10px",
                                float: iconReverse ? "left" : "right",
                            ...iconStyle
                            }}>
                            {iconName}
                        </i>
                    }
                </button>
            </Link>
        );
    }
}