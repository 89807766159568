import {blogService} from "../services";
import {blogConstants} from "../constants";

export const blogActions ={
    Getallblog,
    Viewblog
}

function Getallblog(page){
    return dispatch =>{
         dispatch(request());
        blogService.Getallblog(page).then(
            message =>{
                dispatch(success(message));
            },
            error =>{
                dispatch(failure(error));
            }
        );       
    };
    function request(){
        return {type:blogConstants.ALLBLOG_REQUEST};
    }
    function success(message){
        return {type:blogConstants.ALLBLOG_SUCCESS,message};
    }
    function failure(error){
        return {type:blogConstants.ALLBLOG_ERROR,error};
    }
}

function Viewblog(url){
    return dispatch =>{
        dispatch(request());
        blogService.viewblog(url).then(
            message => dispatch(success(message)), error => dispatch(failure(error))
        );
    };
    function request(){
        return {type:blogConstants.VIEW_REQUEST};
    }
    function success(message){
        return {type:blogConstants.VIEW_SUCCESS,message};
    }
    function failure(error){
        return {type:blogConstants.VIEW_ERROR,error}
    }
}