import React, { Component } from "react";
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

export default class Slider extends Component{
    render(){
        const { 
            noOfCards = 4,
        } = this.props;
        console.log(this.props.children);
        return(
            <Carousel
                arrows = { noOfCards < this.props.children.length ? true : false }
                slidesPerPage = {noOfCards}
            >
                { this.props.children }    
            </Carousel>

        );
    }
} 