import client from "../clientsJSON";

export const utils = {
    ValidateMobileNumber,
    randomColor,
    getLink,
    getScreenDimensions,
    isNotEmpty
}

function ValidateMobileNumber (mobile) {
    let pattern = "[+]{1}[0-9]{12}";
    if(mobile.match(pattern)) return true;
    else return false;
}

function isNotEmpty(value = ""){
    return !["", null, undefined].includes(value);
}

function randomColor(){
    return '#'+Math.random().toString(16).substr(-6);
}

function getLink(menu, index = 0){
    switch(menu.type){
        case "call" : return "tel:" + client.mobile[index];
        case "email" : return "mailto:" + client.email[index];
        default : return menu.link;
    }
}

function getScreenDimensions (type = "w") {
    if(type === "w")
        return window.screen.width;
    return window.screen.height;
}