import React,{Component} from "react";
import appinfo from "../../clientsJSON";
import "../home/index.css";
import "./index.scss";

import {
    Banner,
    Menu,
    Footer,
    DefaultSection,
    AlternateLayout,
    Accordion,
    ContentLayout,
    ContactLayout,
    PricingSection,
    PartnerSection,
    ReviewSection,
    NituWidget,
} from "../../component";

class Pricing extends Component {
    render(){
        document.title = "Pricing | " + appinfo.name;
       
        return (
           
            <div>
                <Menu/>

                    {
                            appinfo.pricing_sections.map((single, index) => {               
                                switch (single.cardType) {
                                    case "reviews": return <ReviewSection
                                        containerProps={single.content.containerProps || {}}
                                        imageProps={single.content.imageProps || {}}
                                        logoProps={single.content.logoProps || {}}
                                        contentProps={single.content.contentProps || []}
                                        header={single.content.header || {}}
                                        backgroundColor={single.content.backgroundColor || "transparent"}
                                        buttonProps={single.content.buttonProps || {}}
                                        slider={single.content.slider || false}
                                        template={single.content.template || 1}
                                        sectionStyle={single.content.sectionStyle || {}}
                                        id = { single.content.id || "reviews" }
                                    />;
                                    case "products": return <DefaultSection
                                        containerProps={single.content.containerProps || {}}
                                        imageProps={single.content.imageProps || {}}
                                        linkProps={single.content.linkProps || {}}
                                        contentProps={single.content.contentProps || []}
                                        header={single.content.header || {}}
                                        backgroundColor={single.content.backgroundColor || "transparent"}
                                        buttonProps={single.content.buttonProps || {}}
                                        slider={single.content.slider || false}
                                        sectionStyle={single.content.sectionStyle || {}}
                                        id = { single.content.id || "products"}
                                    />;
                                    case "partners": return <PartnerSection
                                        containerProps={single.content.containerProps || {}}
                                        imageProps={single.content.imageProps || {}}
                                        logos={single.content.logos || []}
                                        header={single.content.header || {}}
                                        backgroundColor={single.content.backgroundColor || "transparent"}
                                        sectionStyle={single.content.sectionStyle || {}}
                                        id = { single.content.id || "partners"}
                                    />;
                                    case "pricing" : return <PricingSection
                                        containerProps={single.content.containerProps || {}}
                                        imageProps={single.content.imageProps || {}}
                                        iconProps = {single.content.iconProps || {}}
                                        linkProps={single.content.linkProps || {}}
                                        contentProps={single.content.contentProps || []}
                                        header={single.content.header || {}}
                                        backgroundColor={single.content.backgroundColor || "transparent"}
                                        buttonProps={single.content.buttonProps || {}}
                                        slider={single.content.slider || false}
                                        sectionStyle={single.content.sectionStyle || {}}
                                        id = { single.content.id || "pricing"}
                                        cardType = { single.cardType || "pricing"}
                                        template = {single.content.template || 1}
                                    />
                                    case "alternate": return <AlternateLayout
                                        reverse={single.content.reverse || false}
                                        header={single.content.header || {}}
                                        imageProps={single.content.imageProps || {}}
                                        videoProps={single.content.videoProps || {}}
                                        contentProps={single.content.contentProps || {}}
                                        linkProps={single.content.linkProps || {}}
                                        buttonProps = {single.content.buttonProps || {}}
                                        backgroundColor={single.content.backgroundColor || "transparent"}
                                        sectionStyle={single.content.sectionStyle || {}}
                                        id = { single.content.id || "alternate"}
                                    />;
                                    case "content" : return <ContentLayout
                                        title = {single.content.contentProps.title || ""}
                                        description = { single.content.contentProps.description || ""} 
                                        contentStyle = { { ...single.content.contentProps.contentStyle }}
                                        titleStyle = {single.content.contentProps.titleStyle || {}} 
                                        descriptionStyle = {single.content.contentProps.descriptionStyle || {}}
                                        subTitle={single.content.contentProps.subTitle || ""}
                                        subTitleStyle={single.content.contentProps.subTitleStyle || {}}
                                        extraClasses = {single.content.contentProps.extraClasses || "center"}
                                        tag = {single.content.contentProps.tag || {}}
                                        id = { single.content.id || "content"}
                                    />;
                                    case "widget" : return <NituWidget
                                        clientId = { appinfo["x-access-client-id"] || ""}
                                        formId = { appinfo["form"] || ""}
                                        width = { single.content.width || "100%"}
                                        height = { single.content.height || "400px"}
                                        style = { single.content.style || {}}
                                        id = { single.content.id || "widget"}
                                    />
                                    case "banner" : return <Banner
                                        container = {single.content || {}}
                                        template = {single.content.template || 2}
                                        id = { single.content.id || "banner"}
                                    />
                                    case "contact" : return <ContactLayout 
                                            id = { single.content.id || "contact"}
                                        />;
                                    case "accordion" : return <Accordion 
                                            list = {single.content.list || {}} 
                                            sectionStyle = {single.content.sectionStyle || {} }
                                        />;
                                    default : return <div></div>;
                                }
                            })
                    }
                <Footer/>
            </div>
        )
    }    
}
export default Pricing;
