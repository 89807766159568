import React,{Component} from "react";
import appinfo from "../../clientsJSON";
import "../home/index.css";
import { Container, Menu, Footer,
     SingleProduct, SingleProduct1 } from "../../component";
import  BrokenPage  from "../broken_page";

class ServiceDetails extends Component {
    constructor(props){
        super(props);
        this.state={
            sId:""
        }
    }
 
    componentDidMount(){
        let sId = this.props.match.params.id;
        this.setState({sId}); 
    }
   
    render(){
        document.title = "Service | " + appinfo.name;

        let toRender = [];
        let Id=this.state.sId;
        // if(![undefined,null,NaN,""].includes(this.state.sId))
        //     toRender = appinfo.service_sections.filter(
        //                             e =>{
        //                                 return ( e.content.title.toLowerCase() == Id.replace("-"," ").toLowerCase())
        //                             }
        //                 );
        if(![undefined,null,NaN,""].includes(this.state.sId))
            toRender = appinfo.service_page.filter(
                                    e =>{
                                        return ( e.id.toLowerCase() == Id.replace("-"," ").toLowerCase())
                                    }
                        );
        return (
            <div className="mp-container"> 
                <Menu />
                    { toRender.length === 1 &&
                        <SingleProduct1 content={toRender[0].content} /> 
                        // <SingleProduct1 content= {appinfo.service_page}/>
                    } 
                    {
                        toRender.length === 0 &&
                        <BrokenPage 
                            style={{padding: "64px 0px"}}
                            buttonProps = {
                                {
                                    buttonLink : "/products",
                                    buttonText : "see List"
                                }
                            }
                        />
                    } 
                <Footer />
            </div>
        )
    }    
}
export default ServiceDetails;