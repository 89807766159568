import React,{Component} from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import SingleBlog from "../../component/blog/singleblog";
import { Menu,Footer,Team} from "../../component";
import { blogActions } from "../../actions";
import {TopLoader} from "../../component";
import appinfo from "../../clientsJSON";

class SingleBlogArea extends Component{
    constructor(props){
        super(props);
        this.state={
            url:""
        }
    }
    componentDidMount(){
        const {dispatch}=this.props;
        let url = this.props.match.params.url;
        dispatch(blogActions.Viewblog(url))
      }

    render(){
        document.title="Blog |" +appinfo.name;
        const {blog}=this.props;
        return(
            <div>
            {blog.status=="GET_VIEW_REQUEST" && 
            <div>
                <TopLoader/> 
                <p style={{textShadow:"2px",textAlign:"center",margin:50}}>Please Wait,We are Fetching Our best Blogging</p>
            </div>}    
            {blog.status==="GET_VIEW_SUCCESS" &&
            <div>
            <Menu/>
            <SingleBlog content={blog.blog}/>
            <Footer/>
            </div>}
            </div>
        )
    }
}

function mapStateToProps(state){
    const {blog} =state;
    return{
        blog
    }
}
export default withRouter(connect(mapStateToProps)(SingleBlogArea))