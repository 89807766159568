import React, { Component } from "react";
import {
    EmbedUrlLayout, Layout
} from "..";

export default class PricingCard2 extends Component {
    
    render(){
        const {
            contentProps = {},
            containerProps = {}
        } = this.props;
        console.log(contentProps);
        return(
            <Layout
                extraClasses = { containerProps.extraClasses || ""}
                columns = { containerProps.columns || 1}
                layoutStyle = { containerProps.layoutStyle || {}}
            > 
                <details open>
                    <summary >{ contentProps.title }</summary>
                    <EmbedUrlLayout
                        source = {contentProps.link}
                        width="95%" 
                        height="1000" 
                        style = {{background: "transparent", border: "1px solid #eee", ...contentProps.linkStyle}}
                    />
                </details>
            </Layout>
        );
    }
}
