import React, { Component } from "react";
import { Container, DefaultSection } from "../../component";

import jso from "../../dummy.json";
import "../../index.css";

class Dummy extends Component{
    constructor (props)
    {
        super(props);
    }    

    render(){
        let single = jso.pricing;
        return(
            <div>
            </div>
        );
    }
}

export default Dummy;