import React, { Component } from "react";
import {
    SingleImageLayout
} from "../";

export default class ProductGalleryLayout extends Component {

    constructor (props) {
        super(props);
        this.state = {
            imageIndex : 0
        }
    }

    changeImage = ( imageIndex = 0 ) => {
        this.setState ({
            imageIndex
        })
    }

    render(){
        const { 
            imageProps  = []
        } = this.props;
        const { imageIndex } = this.state;

        return (
            <div className="product">
              <div className="product-small-img">
                {
                    imageProps.length !== 0 &&
                        imageProps.map( (image, index ) => {
                            return (
                                <SingleImageLayout
                                    imageSource = {image.imageSource || ""}
                                    imageAlt = { image.imageAlt || "Preview Image"}
                                    id = { `imagePreview-${index}` }
                                    onClick = { () => this.changeImage(index) }
                                />
                            );
                })}
              </div>
              <div className="img-container">
                    {
                        imageProps.length !== 0 &&
                        <SingleImageLayout
                            id = {"FullImage"}
                            imageSource = {imageProps[imageIndex].imageSource}
                            imageAlt = {"Full Image"}
                            imageWidth = {imageProps[imageIndex].imageWidth || "auto"}
                            imageHeight = { imageProps[imageIndex].imageHeight || "auto"}
                        />
                  }
              </div>
            </div>
        );
    }
}