import React, {Component} from "react";
import { Button, ContentLayout, SingleImageLayout } from "../../component";
import broken from "../../images/broken.jpg";

export default class BrokenPage extends Component {
    render(){
        const { style = {}, buttonProps = {} } = this.props;
        return(
            <div className="template-6 center" style={style}>
                <SingleImageLayout 
                    imageSource={broken}
                    imageWidth={"50%"}
                    imageHeight={"50%"}
                    imageAlt={"Broken Page"}
                />
                <ContentLayout 
                    subTitle={"OOPS !! Page you are looking for is not found."}
                />
                <Button 
                    buttonText={buttonProps.buttonText || "HOME"} 
                    buttonLink={buttonProps.buttonLink || "/"}
                    showIcon = { buttonProps.showIcon || true }
                    iconName = {buttonProps.iconName || "home" }
                    iconReverse = { buttonProps.iconReverse || true } 
                    iconStyle = { buttonProps.iconStyle || {fontSize: "18px"}}
                />
            </div>
        );
    }
}