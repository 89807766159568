//Redux

import {applyMiddleware, combineReducers, createStore} from "redux";
import ReduxThunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import {persistReducer}  from "redux-persist";
//Navigation

import {authentication} from "./authentication.reducer";
import { endpoint } from "./endpoint.reducer";
import {taskcreate} from "./taskcreate.reducer";
import {blog} from "./blog.reducer";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["authentication"]
};

const middleware = () => {
  return applyMiddleware(ReduxThunk);
  
};

const pReducer =persistReducer(
    persistConfig,
    combineReducers({
    authentication,
    endpoint,
    taskcreate,
    blog
    })
);

export default createStore(pReducer, middleware());