import React from 'react';
import { Provider } from 'react-redux';
import { 
        BrowserRouter,
        Route,
        Switch
      } from "react-router-dom";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';

import Home from "./screens/home";
import About from "./screens/about";
import BrokenPage from "./screens/broken_page";
import Services from "./screens/services";
import ServiceDetails from "./screens/services/serviceDetails";
import Contact from "./screens/contact";
import Terms from "./screens/terms";
import Privacy from "./screens/privacy";
import Refund from "./screens/refund";
import RootLoader from "./screens/root_loader";
import Login from "./screens/login";
import Pricing from "./screens/pricing";
import Teams from './screens/teams';
import appinfo from "./clientsJSON";
import MediaNews from "./screens/medianews";
import Blog from "./screens/blogs";
import SingleBlogArea from "./screens/blogs/singleblogarea";
import Dummy from "./screens/home/dummy.js";

import './App.css';
import appInfo from "./clientsJSON";

const App = ({store}) => {

  let pages = {
    services : [
      { routeName : "/services", classComponent : Services },
      { routeName : "/services/:id", classComponent : ServiceDetails },
    ],
    Products : [
      { routeName : "/products", classComponent : Services },
      { routeName : "/products/:id", classComponent : ServiceDetails },
    ],
    media : [ { routeName : "/media", classComponent : MediaNews } ],
    team : [ { routeName : "/team", classComponent : Teams } ],
    pricing : [ { routeName : "/pricing", classComponent : Pricing } ]
  };

  return(
    <Provider store={store}>
    <PersistGate 
      loading={null} 
      persistor={persistStore(store)} 
    >
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={RootLoader} />
            <Route exact path="/about-us" component={About}/>
            <Route exact path="/contact-us" component={Contact}/>
            <Route exact path="/home" component={Home}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/privacy-policy" component={Privacy}/>
            <Route exact path="/refund-policy" component={Refund}/>
            <Route exact path={"/blog"} component={Blog}/>
            <Route path={"/blog/:url"} component={SingleBlogArea}/>
            <Route path={"/dummy"} component={Dummy}/>
            <Route exact path="/terms-and-conditions" component={Terms}/>
            {/* {
              appinfo.pages.map( page => {
                console.log("page", page);
                console.log(page.show , pages[page.key].length)
                page.show && pages[page.key].length !== 0 &&
                  pages[page.key].map( i => {
                    console.log(i.routeName, i.classComponent);
                    console.log(<Route exact path={i.routeName} component={[i.classComponent]}/>)
                    return <Route exact path={i.routeName} component={i.classComponent}/>;
                    }
                  )
              })
            } */}
            {
              appinfo.pricing_show &&  
              <Route exact path="/pricing" component={Pricing}/>
            }
            {
              appinfo.services_show &&
              <Route exact path={"/"+appinfo.services.title} component={Services}/>
            }
            {
              appinfo.services_show &&
              <Route path={"/"+appinfo.services.title+"/:id"} component={ServiceDetails}/>
            }
            {
              appinfo.team_show &&
              <Route exact path="/team" component={Teams}/>
            }
            {
              appinfo.media_show &&
              <Route exact path="/media" component={MediaNews}/>
            }
            <Route component={BrokenPage} />
          </Switch>
        </BrowserRouter>
    </PersistGate>
    </Provider>
  )
}

export default App;