import {authenticationConstants}  from "../constants";

const initialState ={
    otp:[],
    result:[],
    available: [],
    error: []
};

export function authentication(state = initialState,action){
    switch (action.type){
        case authenticationConstants.SENDINGOTP_REQUEST:
            return{
                ...state,
                status:"SENDINGOTP_REQUEST"
            };
        case authenticationConstants.SENDINGOTP_SUCCESS:
            return{
                ...state,
                status:"SENDINGOTP_SUCCESS",
                otp:action.otp,
            };
        case authenticationConstants.SENDINGOTP_ERROR:
            return{
                ...state,
                status:"SENDINGOTP_ERROR",
                error: action.error,
            }

        case authenticationConstants.LOGIN_REQUEST:
           return{
               ...state,
               status:"LOGIN_REQUEST"
           };
        case authenticationConstants.LOGIN_SUCCESS:
            return{
                ...state,
                status:"LOGIN_SUCCESS",
                user:action.user
                
            };
        case authenticationConstants.LOGIN_ERROR:
            return{
                ...state,
                status:"LOGIN_ERROR",
                error: action.error
            };
        case authenticationConstants.ISAVAILABLE_REQUEST:
            return{
                ...state,
                status:"ISAVAILABLE_REQUEST"
            };
        case authenticationConstants.ISAVAILABLE_SUCCESS:
            return{
                ...state,
                status:"ISAVAILABLE_SUCCESS",
                available: action.user
                
            };
        case authenticationConstants.ISAVAILABLE_ERROR:
            return{
                ...state,
                status:"ISAVAILABLE_ERROR",
                error: action.error
            };
            
        case authenticationConstants.SIGNUP_REQUEST:
            return{
                ...state,
                status:"SIGNUP_REQUEST"
            };
        case authenticationConstants.SIGNUP_SUCCESS:
            return{
                ...state,
                status:"SIGNUP_SUCCESS",
                user:action.user
                
            };
        case authenticationConstants.SIGNUP_ERROR:
            return{
                ...state,
                status:"SIGNUP_ERROR",
                error: action.error
            };
        case authenticationConstants.LOGOUT:
            return{
                status:"LOGGED_OUT"
            };
        default:
            return state; 
    }
} 