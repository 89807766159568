import React, { Component } from "react";
import Typewriter from 'typewriter-effect';
import { Layout } from "..";

export default class TypingEffect extends Component {
    render(){

        const { 
            loop = true,
            strings = [] ,
            extraClasses = ""
        } = this.props;

        return( 
            <Layout 
                extraClasses = { extraClasses   || "center" } 
            >
                <Typewriter
                    options={{
                        strings ,
                        autoStart: true,
                        loop,
                    }}
                />
            </Layout>
        )
    }
}