import React, { Component } from "react";
import {
    Button,
    ContentLayout,
    Link,
    SingleImageLayout
} from "..";
import appinfo from "../../clientsJSON";

export default class BannerTemplate6 extends Component {
    render(){
        const { 
            container = {}
        } = this.props;
        
        let width = window.screen.width >=  768 ? 
                        ( window.screen.width / 2 ) - 120 : 
                        window.screen.width - 60 ;
        let buttons = container.buttons; 
        let links = container.links;

        return(
            <div className="banner-container-home template-6 center" 
                style={{    
                            backgroundColor: appinfo.background_color,
                            flexDirection: window.screen.width >=  768 ? (container.reverse ? "row-reverse" : "row" ) : "column-reverse"
                        }}>
                <div className="banner-image center template-6-inner-section">
                    <SingleImageLayout
                        imageSource = {container.image_path}
                        imageAlt= {"Hero Banner"}
                        imageWidth = { width }
                        imageHeight = { "500px" }
                    />
                </div>
                <div className="banner-container-left-home template-6-inner-section left" 
                    style={{minWidth: width -60, minHeight: "400px", flexDirection: "column"}}>
                        <ContentLayout 
                            {...container.contentProps} 
                        />
                        { buttons !== undefined &&
                            <div className="flex wrap left">
                                {
                                    buttons.map( buttonProp => 
                                        <Button
                                            buttonText = { buttonProp.buttonText || "" } 
                                            buttonStyle = {{ ...buttonProp.buttonStyle , margin: "10px" }}
                                            extraClasses = { buttonProp.extraClasses ||  ""}
                                            showIcon = { buttonProp.showIcon || false }
                                            iconName = { buttonProp.iconName || ""}
                                            iconReverse = { buttonProp.iconReverse || false } 
                                            iconStyle = { buttonProp.iconStyle || {}}
                                            buttonLink = { buttonProp.buttonLink || ""}
                                            newTab = { buttonProp.newTab || false}
                                        />
                                    )
                                }   
                            </div>
                        }
                        { 
                            links !== undefined &&
                            <div className="flex wrap left"> 
                                {   
                                    links.map( linkProps =>
                                    <Link
                                        linkStyle = {linkProps.linkStyle || {}}
                                        link = {linkProps.link || ""}
                                        linkText = { linkProps.linkText || ""}
                                        showIcon = {linkProps.showIcon || false }
                                        iconName = {linkProps.iconName || ""}
                                        iconReverse = { linkProps.iconReverse || false } 
                                        iconStyle = { linkProps.iconStyle || {}}
                                        newTab = { linkProps.newTab || false }
                                        extraClasses = { linkProps.extraClasses || ""}
                                    />
                                    )
                                }
                            </div>
                        }
                </div>
            </div>
        );
    }
}