import React, { Component } from "react";
import appinfo from "../../clientsJSON";
import "../home/index.css";
import {
    Container,
    ContentLayout,
    Timeline
} from "../../component";

class MediaNews extends Component {
    render() {
        document.title = "Media | " + appinfo.name;

        return (
        <Container>
                <div className="padding-60">
                {
                    appinfo.media_sections.map(
                        ( media, index ) => {

                            switch(media.cardType){
                                case "content" : return <ContentLayout
                                                    title = {media.content.contentProps.title || ""}
                                                    description = { media.content.contentProps.description || ""} 
                                                    contentStyle = { { ...media.content.contentProps.contentStyle }}
                                                    titleStyle = {media.content.contentProps.titleStyle || {}} 
                                                    descriptionStyle = {media.content.contentProps.descriptionStyle || {}}
                                                    subTitle={media.content.contentProps.subTitle || ""}
                                                    subTitleStyle={media.content.contentProps.subTitleStyle || {}}
                                                    extraClasses = {media.content.contentProps.extraClasses || "center"}
                                                    tag = {media.content.contentProps.tag || {}}
                                                    id = { media.content.id || "content"}
                                                />;

                                case "timeline" : 
                                        return <Timeline
                                                    containerProps = {media.content.containerProps || {}}
                                                    imageProps = {media.content.imageProps || {}}
                                                    linkProps = {media.content.linkProps || {}}
                                                    contentProps = { media.content.contentProps || []}
                                                    header = { media.content.header || {}}
                                                    backgroundColor = {media.content.backgroundColor || "transparent"}
                                                    buttonProps= {media.content.buttonProps || {}} 
                                                    sectionStyle = {media.content.sectionStyle || {}}
                                                    id = { media.content.id || "media"}
                                                />;
                                default : return <div></div>;
                            }
                        }
                    )
                }
            </div>
        </Container>
        )
    }
}
export default MediaNews;