import React, { Component } from "react";

export default class List extends Component{

    render() {
        const { 
            listStyle = {}, 
            listTextStyle= {},
            list = [],
            showIcon = false, 
            iconReverse = false,
            listExtraClasses = "",
            listTextExtraClasses = "",
            iconExtraClasses = "",
            iconStyle = {},
        } = this.props;

        return(
            <ul style = {listStyle}    className = { listExtraClasses }>
                {
                    list.length !== 0 &&
                    list.map( i => {
                        return <li  
                            style={{ 
                                textDecoration: "none",
                                listStyle: "none",
                                ...listTextStyle 
                            }}
                            className = {listTextExtraClasses}
                        >
                            { i.listText || this.props.children }
                            {
                                showIcon &&
                                    <i className = { iconExtraClasses || "material-icons"} 
                                        style={{
                                            verticalAlign: "sub", 
                                            padding: "0px 10px",
                                            height: "16px", 
                                            fontSize: "18px",
                                            paddingLeft : iconReverse ? "0px" : "10px",
                                            paddingRight: !iconReverse ? "0px" :"10px",
                                            float: iconReverse ? "left" : "right",
                                            ...iconStyle 
                                        }}>
                                        {i.iconName}
                                    </i>
                            }
                        </li>
                    })
                }
            </ul>
        );
    }
}