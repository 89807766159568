import {endpointConstants} from "../constants";
const initialState ={
    status: 0
};

export function endpoint(state =initialState,action){
    switch(action.type){
        case endpointConstants.PING_REQUEST:
            return{
                ...state,
                status:"PING_REQUEST"
            };
        case endpointConstants.PING_SUCCESS:
            return {
                ...state,
                status: "PING_SUCCESS"
            };
        case endpointConstants.PING_ERROR:
            return {
                ...state,
                status: "PING_ERROR"
            };
        default:
            return state;
    }
}