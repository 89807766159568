import React, { Component } from "react";
import { 
    ReviewContentLayout,
    Layout, 
    SingleImageLayout, 
} from "..";

export default class Reviewcard2 extends Component {

    render(){
        const {
            containerProps = {},
            imageProps = {},
            contentProps = {}
        } =  this.props;

        return(
            <Layout 
                columns={1} 
                extraClasses={containerProps.extraClasses + " review-card-flex flex center"} 
                layoutStyle={{ ...containerProps.layoutStyle }}
            >
                { 
                    !["", undefined, null].includes(contentProps.review)  &&
                    <blockquote>
                        <ReviewContentLayout
                            review={contentProps.review || ""}
                            reviewStyle={contentProps.reviewStyle || {}}
                            name={contentProps.name || ""}
                            reviewNameStyle={contentProps.reviewNameStyle || {}}
                            designation={contentProps.designation || ""}
                            reviewDesignationStyle={contentProps.reviewDesignationStyle || {}}
                            contentStyle={contentProps.contentStyle || {}}
                            extraClasses={contentProps.extraClasses || ""}
                        />
                    </blockquote>
                }
                { !["", undefined, null].includes(imageProps.imageSource) &&
                    <SingleImageLayout 
                        imageSource={imageProps.imageSource || ""} 
                        imageStyle={{ ...imageProps.imageStyle }} 
                        imageWidth={imageProps.imageWidth || "auto"} 
                        imageHeight={imageProps.imageHeight || "auto"}
                        imageAlt={imageProps.imageAlt || ""}
                        imageContainerStyle = {{ ...imageProps.imageContainerStyle }}
                        extraClasses = {imageProps.extraClasses || ""}
                        imageExtraClasses = {" objectFit"}
                    />
                }
            </Layout>
        );
    }
} 