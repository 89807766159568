import React, { Component } from "react";
import { 
    ContentLayout, 
    Layout, 
    SingleImageLayout, 
    Link,
    List
} from "..";

export default class PricingCard1 extends Component {
    
    render(){
        const {
            containerProps = {},
            imageProps = {},
            linkProps = {},
            contentProps = {},
            iconProps = {}
        } =  this.props;

        return(
            <Layout 
                columns={containerProps.columns || 3} 
                extraClasses={containerProps.extraClasses || "center space"} 
                layoutStyle={containerProps.layoutStyle || {}}
            >
                { !["", undefined, null].includes(imageProps.imageSource) &&
                    <SingleImageLayout 
                        imageSource={imageProps.imageSource || ""} 
                        imageStyle={imageProps.imageStyle || {}} 
                        imageWidth={imageProps.imageWidth || "100%"} 
                        imageHeight={imageProps.imageHeight || "100%"}
                        imageAlt={imageProps.imageAlt || ""}
                        imageContainerStyle={imageProps.imageContainerStyle || {}}
                        extraClasses = {imageProps.extraClasses || ""}
                    />
                }
                { 
                    (!["", undefined, null].includes(contentProps.description) ||
                    !["", undefined, null].includes(contentProps.title) ) &&
                    <ContentLayout
                        title={contentProps.title || ""}
                        titleStyle={contentProps.titleStyle || {}}
                        subTitle={contentProps.subTitle || ""}
                        subTitleStyle={contentProps.subTitleStyle || {}}
                        description={contentProps.description || ""}
                        descriptionStyle={contentProps.descriptionStyle || {}}
                        contentStyle={contentProps.contentStyle || {}}
                        extraClasses={contentProps.extraClasses || ""}
                        tag = {{ title: "h2", subTitle: "h4" , ...contentProps.tag }}
                    />
                }
                {
                    (!["", undefined, null].includes(contentProps.list) &&
                        contentProps.list.length !== 0 ) &&
                    <List
                        list = { contentProps.list || []}
                        listStyle = { contentProps.listStyle || {}}
                        listTextStyle= { contentProps.listTextStyle || {}}
                        showIcon = { iconProps.showIcon || false } 
                        iconReverse = { iconProps.iconReverse || false }
                        listExtraClasses = { contentProps.listExtraClasses || "" }
                        listTextExtraClasses = { contentProps.listTextExtraClasses || "" }
                        iconExtraClasses = { iconProps.iconExtraClasses || "" }
                        iconStyle = { iconProps.iconStyle || {}}
                    />
                }
                { 
                    !["", undefined, null].includes(linkProps.link) &&
                    <Link
                        linkStyle = {linkProps.linkStyle || {}}
                        link = {linkProps.link || ""}
                        linkText = { linkProps.linkText || ""}
                        showIcon = {linkProps.showIcon || false }
                        iconName = {linkProps.iconName || ""}
                        iconReverse = { linkProps.iconReverse || false } 
                        iconStyle = { linkProps.iconStyle || {}}
                        newTab = { linkProps.newTab || false }
                        extraClasses = { "flex center padding-20 " + linkProps.extraClasses }
                    />
                }

            </Layout>
        );
    }
}