import React,{Component} from 'react';
import "../../screens/home/index.css";
import appinfo from "../../clientsJSON";

import BannerTemplate1 from "./bannerTemplate1";
import BannerTemplate2 from "./bannerTemplate2";
import BannerTemplate3 from "./bannerTemplate3";
import BannerTemplate4 from "./bannerTemplate4";
import BannerTemplate5 from "./bannerTemplate5";
import BannerTemplate6 from "./bannerTemplate6";

export default class Banner extends Component{    
    
  render(){
      const { 
            container = appinfo.container || {},
            template =  2
        } = this.props;

      let style={};
      if (template === 2) {
          style["minHeight"] = 500;
          style["backgroundImage"] = `url(${container.image_path})`;
      }
      
      return (
        <div  style={{marginTop:60}}>
            {
                template === 1 &&
                <BannerTemplate1 
                    carousel_images = { container.carousel_images }
                />
            }
            {
                template === 2 &&
                <BannerTemplate2
                    style = {style}
                    showWidget = {container.showWidget || true}
                    widgetProps = {container.widgetProps || {}}
                />
            }
            {
                template === 3 &&
                <BannerTemplate3
                    showWidget = {container.showWidget ||true}
                    widgetProps = {container.widgetProps || {}}
                    container = {container}
                />
            }
            {
                template === 4 &&
                <BannerTemplate4
                    showWidget = {container.showWidget ||true}
                    widgetProps = {container.widgetProps || {}}
                    container = {container}
                />
            }

            {
                template === 5 &&
                <BannerTemplate5
                    container = {container}
                />
            }
            {
                template === 6 &&
                <BannerTemplate6
                    container = {container}
                />
            }
        
        </div>
      );
    }
}