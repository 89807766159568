import React, { Component } from "react";


export default class Layout extends Component {
    render(){
        const { layoutStyle = {}, columns = 1, extraClasses="" } = this.props;
        
        return (
            <div 
                className={`layout-column layout-column-${columns} ${extraClasses}`} 
                style={layoutStyle}
            >
                { this.props.children }
            </div>
        )
    }
};