import React, { Component } from "react";
import {
    EmbedUrlLayout
} from "../";

export default class NituWidget extends Component {
    render(){
        const { 
            clientId = "",
            formId = "",
            width = "100%",
            height = "400px",
            style = {}
        } = this.props;

        return (
            <EmbedUrlLayout
                source = {`https://development.moonproduct.in?id=${clientId}&form=${formId}`}
                width = {width}
                height = {height}
                allowFullScreen = {false}
                style = {style}
            />
        )
    }
}