import React, { Component } from "react";
import { ContentLayout, Layout } from "..";

export default class ListViewLayout extends Component {

    render() {
        const { 
            title = "",
            titleStyle = "",
            listText = [],
            listStyle = {},
            containerProps = {},
            tag = { title: "h3"}
        } = this.props;

        return (
                <Layout
                    extraClasses = { containerProps.extraClasses || {}}
                    layoutStyle = { containerProps.layoutStyle || {} }
                    columns = { containerProps.columns || 3 }
                >
                  <div className="features" id="features">
                        <ContentLayout
                            title = { title || ""}
                            titleStyle = { titleStyle || {}}
                            tag = { tag || { title: "h3"}}
                        />
                      <ul>
                        {
                            listText.length !== 0 &&
                            listText.map(
                                i => {
                                    return (
                                        <li style={listStyle} >
                                            { i }
                                        </li>
                                    )
                                }
                            )
                        }
                      </ul>
                  </div>
                </Layout>
        );
    }
}