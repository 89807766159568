import React, { Component } from "react";
import { Layout, MayIHelpYou, Menu, Footer } from "..";


export default class Container extends Component {
    render(){
        const { layoutStyle = {} } = this.props;        
        return (
            <Layout 
                layoutStyle={{ ...layoutStyle , borderRadius : 0}}
            >
                <Menu />
                    <div className="container" >
                        { this.props.children }
                    </div>
                <Footer />
                <MayIHelpYou />
            </Layout>              
        )
    }
};