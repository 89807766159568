import React,{Component} from "react";
import appinfo from "../../clientsJSON";
import "../home/index.css";
import {
    Container,
    Team,
    TeamSection
} from "../../component";

class Teams extends Component {
    render(){
        document.title = "Team | " + appinfo.name;
       
        return (
            <Container >
                {
                    appinfo.team_sections.map(
                        ( team, index ) => {

                            switch(team.cardType){
                                case "team" : 
                                        return <TeamSection
                                                    containerProps = {team.content.containerProps || {}}
                                                    imageProps = {team.content.imageProps || {}}
                                                    linkProps = {team.content.linkProps || {}}
                                                    contentProps = { team.content.contentProps || []}
                                                    header = { team.content.header || {}}
                                                    backgroundColor = {team.content.backgroundColor || "transparent"}
                                                    buttonProps= {team.content.buttonProps || {}} 
                                                    sectionStyle = {team.content.sectionStyle || {}}
                                                    id = { team.content.id || "team"}
                                                />;
                                default : return <div></div>;
                            }
                        }
                    )
                }
                
            </Container>

        )
    }    
}
export default Teams;