import React,{Component} from "react";
import "./index.css";
import appinfo from "../../clientsJSON"

class Client extends Component{
    render(){
        const {title={},content={}}=this.props;
        return(
            <div className="mpcard mpcenter">
            <h2 style={{color:appinfo.primaryTextColor}} >{title}</h2>
            <div style={{marginTop: 8,width: 90,height: 3,backgroundColor:"none"}}/>
            <div className="mpcardContent">
             {content.map(i=>{
                 return(
                    <img style={{padding:20}} style={{padding:20}} src={i.image_url} alt={i.alt}style={{height:80,margin:"20 auto 0 auto",padding:"0 20 0 20"}} />
                   
                 )
             })}
            </div>
            </div>
 
            
        )
    }
}
export default Client;