import React, { Component } from "react";
import { 
    ContentLayout, 
    Layout,
    TeamCard
} from "..";

export default class TeamSection extends Component{


    renderDetail = (contentProp = {}) => {
        const{ 

            containerProps = {},
            imageProps = {},
            linkProps = {}

        } = this.props;

        let imageProp = { ...imageProps, imageSource: contentProp.imageSource };

        let linkProp = { ...linkProps, link: contentProp.link || linkProps.link };

        return <TeamCard
                containerProps={containerProps || {}}
                imageProps =  {imageProp || {}}
                linkProps = {linkProp || {}}
                contentProps = {contentProp || {}}
            />;
    }

    render(){
        const {  
            contentProps = [], 
            header = {}, 
            backgroundColor = "#000",
            sectionStyle = {}
        } = this.props;


        return(
        <Layout
            layoutStyle={{backgroundColor, ...sectionStyle }}
            extraClasses = "no-radius"
        >
            <div className="container">
                <ContentLayout
                    title={header.title || ""}
                    description={header.description || ""}
                    titleStyle={header.titleStyle || {}}
                    descriptionStyle={header.descriptionStyle || {}}
                    subTitle={header.subTitle || ""}
                    subTitleStyle={header.subTitleStyle || {}}
                    contentStyle={header.contentStyle || {}}
                    extraClasses={header.extraClasses || "padding-50"}
                    tag = {{ title: "h2", subTitle: "h4"  }}
                />
                <div className="flex wrap">
                    { 
                        contentProps.map( content => <>{
                            this.renderDetail(content)
                        }</>)
                    }
                </div>
            </div>
        </Layout>
        );
    }
}