import React, { Component } from "react";
import {
    ContentLayout,
    TypingEffect,
    NituWidget
} from "..";
import appinfo from "../../clientsJSON";

export default class BannerTemplate4 extends Component {
    render(){
        const {
            showWidget = true,
            widgetProps = {},
            container = {}
         } = this.props;
        
        let width = window.screen.width >  768 ? window.screen.width / 2: window.screen.width;
        let template = container.template || 4;
        let mobile = window.screen.width < 768;

        return(
            <div className="banner-container-home" 
                style={{    
                            flexDirection: mobile ? "column" : 
                            ( container.container_reverse ? "row" : "row-reverse"), 
                            backgroundColor: appinfo.background_color,
                            backgroundImage: container.image_path ? 
                                        `url(${container.image_path})` : "",
                            backgroundRepeat : "no-repeat"
                        }}>
                { template === 4 &&
                    <div className="banner-container-left-home" 
                        style={{minWidth: width, minHeight: "600px"}}>
                        {   showWidget &&
                            <NituWidget
                                clientId = {appinfo["x-access-client-id"]}
                                formId = {appinfo["form"]}
                                width = { widgetProps.width || "100%" }
                                height = { widgetProps.height ||  "400px" }
                                style = { widgetProps.style || {}}
                            />
                        }
                    </div>
                }
                <div className="banner-image center" 
                    style={
                        {
                            minWidth: template === 4 ? width : "100%",
                            minHeight: "400px", 
                            marginTop: "10%"
                        }
                    }
                >
                    <ContentLayout 
                        {...container.contentProps} 
                    />
                    {   container.typingEffect !== undefined &&
                        container.typingEffect.strings.length !== 0 &&
                        <TypingEffect
                            loop = { container.typingEffect.loop || true }
                            strings = { container.typingEffect.strings || [] }
                        />
                    }
                </div>
            </div>
        );
    }
}