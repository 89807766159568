import React,{Component} from 'react';
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import "../home/index.scss";
import appinfo from "../../clientsJSON";
import { authenticationActions, taskcreateActions } from "../../actions";
import { utils } from "../../utils/utils";

const notyf = new Notyf({
types: [
  {
    type: 'success',
    background: 'green',
    duration:3000,
    dismissible:true,
    position: {
        x: 'center',
        y: 'top',
      },
  },
  {
    type: 'error',
    background: 'indianred',
    duration: 3000,
    dismissible: true,
    position: {
        x: 'center',
        y: 'top',
      },
  }
]});
class Login extends Component{

    constructor(props){
        super(props);
        let params = this.props.location.state ;

        this.state = {
            mobile: appinfo["home-country-code"],
            otp: "",
            name: "",
            mail: "",
            referral: "",
            task: params !== undefined ? params.task : {},
            is_available : false,
            processing: false,
            error : false
        }
    }
    componentDidMount(){
        document.getElementById("start").checked = true ;
    }

    isAvailable = (from = "") => {
        const { dispatch } = this.props;
        const { mobile, mail } = this.state;
        if(from == "mobile"){
            if(appinfo["home-country-code"] == "+91"){
                let payload ={ phone_number: mobile };
                dispatch(authenticationActions.isAvailable(payload));
                this.setState({ processing: true });
            }else{
                document.getElementById("progress2").checked = true
            }
        }
        else if(from == "mail"){
            let payload ={ mail_id : mail };
            dispatch(authenticationActions.isAvailable(payload));
            this.setState({ processing: true });
        }
    }

    forOtp = () => {
        const { dispatch } = this.props;
        const { mobile, mail } = this.state;
        let payload ={ }
        if(appinfo["home-country-code"] == "+91") payload["phone_number"] = mobile ;
        else payload["mail"] = mail;
        dispatch(authenticationActions.sendOtp(payload));
        this.setState({ processing: true });
    }

    handleSubmit = () => {
        const { dispatch } = this.props;
        const { mail, mobile, name, referral } = this.state;
        let payload = { "otp": this.state.otp };

        if(this.state.is_available){
            payload["name"] = name;
            payload["country_code"] = appinfo["home-country-code"];
            payload["mail"] = mail;
            payload["phone_number"] = mobile;
            if(referral != "")
                payload["referred_by"] = referral;
            dispatch(authenticationActions.signup(payload));
        } else {
            if(appinfo["home-country-code"] == "+91") payload["phone_number"] = mobile;
            else payload["mail"] = mail;
            dispatch(authenticationActions.login(payload));
        }
        this.setState({ processing: true });
    }

    UNSAFE_componentWillReceiveProps(nxtProps){
        const { authentication } =this.props;
        let hasPropsChanged = authentication.status !== nxtProps.authentication.status ;
        let notProcessing = ["SENDINGOTP_SUCCESS", "SENDINGOTP_ERROR",
                             "LOGIN_SUCCESS", "LOGIN_ERROR",
                            "SIGNUP_SUCCESS", "SIGNUP_ERROR",
                             "ISAVAILABLE_SUCCESS", "ISAVAILABLE_ERROR"];

        if( hasPropsChanged ) {
            if(nxtProps.authentication.status === "ISAVAILABLE_SUCCESS"){
                this.setState({ is_available: nxtProps.authentication.available.is_available, processing: false});
                this.forOtp();
            }

            if(nxtProps.authentication.status === "SENDINGOTP_SUCCESS"){
                this.setState({processing: false, error: true});
                if(appinfo["home-country-code"] == "+91"){
                    document.getElementById("progress2").checked = true ;
                    notyf.open({type:'success', message:"OTP sent to your Mobile Number/Email"});
                }
                else{
                    document.getElementById("progress3").checked = true ;
                     notyf.open({type:'success', message:"OTP sent to your Email Id"});
                }
            }

            if(notProcessing.includes(nxtProps.authentication.status)){
                this.setState({ processing: false });
            }
            if(nxtProps.authentication.status.indexOf("ERROR") != -1)
                notyf.open({type:'error',message:nxtProps.authentication.error.message});
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value.trim(), error: false });
    }


    render()
    {   
        const { error} = this.state;
        const { authentication } =this.props;

        return(
        <div className="body1">
            <div style={{marginLeft: "auto", marginRight: "auto", marginTop: "10%"}}>
                    <input className="c-checkbox" type="checkbox" id="start"/>
                    <input className="c-checkbox" type="checkbox" id="progress2"/>
                    { appinfo["home-country-code"] != "+91" &&
                        <input className="c-checkbox" type="checkbox" id="progress3"/>
                    }
                    { this.state.is_available &&
                        <input className="c-checkbox" type="checkbox" id="progress4"/>
                    }
                    {   appinfo.referral.show && this.state.is_available &&
                        <input className="c-checkbox" type="checkbox" id="progress5"/>
                    }
                    <input className="c-checkbox" type="checkbox" id="finish"/>
                    <div className="c-form__progress"></div>

                    <div className="c-formContainer">
                    
                    <form className="c-form" action="">
                        <div className="c-form__group">
                            <label className="c-form__label" for="mobile">
                                <input
                                    type="text"
                                    id="mobile"
                                    className="c-form__input"
                                    name="mobile"
                                    value={this.state.mobile}
                                    onChange={this.onChange}
                                    // pattern = "[+]{1}[0-9]{2,12}"
                                    required/>

                                <label className="c-form__next" 
                                    onClick={() => this.isAvailable("mobile")} 
                                    role="button"
                                >
                                    <span className="c-form__nextIcon"></span>
                                </label>

                                <span className="c-form__groupLabel">Mobile Number</span>
                                <b className="c-form__border"></b>
                            </label>
                        </div>

                        { 
                        appinfo["home-country-code"] != "+91" &&
                            <div class="c-form__group">
                                <label class="c-form__label" for="mail">
                                    <input
                                        type="email"
                                        id="mail"
                                        name="mail"
                                        class="c-form__input"
                                        placeholder=" "
                                        value={this.state.mail}
                                        onChange={this.onChange}
                                        required/>

                                    <label class="c-form__next" role="button"
                                        onClick={() => this.isAvailable("mail")}>
                                        <span class="c-form__nextIcon"></span>
                                    </label>

                                    <span class="c-form__groupLabel">Email</span>
                                    <b class="c-form__border"></b>
                                </label>
                            </div>
                       }

                        { this.state.is_available &&
                            <div class="c-form__group">
                                <label class="c-form__label" for="name">
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        class="c-form__input"
                                        placeholder=" "
                                        value={this.state.name}
                                        onChange={this.onChange}
                                        required/>

                                    <label class="c-form__next" for="progress4" role="button">
                                        <span class="c-form__nextIcon"></span>
                                    </label>

                                    <span class="c-form__groupLabel">Your Name ?</span>
                                    <b class="c-form__border"></b>
                                </label>
                            </div>
                       }  

                        { appinfo.referral.show && this.state.is_available &&
                            <div class="c-form__group">
                                <label class="c-form__label" for="referral">
                                    <input
                                        type="text"
                                        id="referral"
                                        name="referral"
                                        class="c-form__input"
                                        placeholder=" "
                                        value={this.state.referral}
                                        onChange={this.onChange}
                                        required/>

                                    <label class="c-form__next" for="progress5" role="button">
                                        <span class="c-form__nextIcon"></span>
                                    </label>

                                    <span class="c-form__groupLabel">Referreal Code ?</span>
                                    <b class="c-form__border"></b>
                                </label>
                            </div>
                       }   

                        <div className="c-form__group">
                            <label className="c-form__label" for="otp">
                                <input
                                    type="numeric"
                                    id="otp"
                                    name="otp"
                                    maxLength={6}
                                    pattern="[0-9]{4,6}"
                                    className="c-form__input"
                                    placeholder=" "
                                    value={this.state.otp}
                                    onChange={this.onChange}
                                    required/>

                                <label className="c-form__next" 
                                    onClick={this.handleSubmit} role="button">
                                    <span className="c-form__nextIcon"></span>
                                </label>

                                <span class="c-form__groupLabel">Enter OTP</span>
                                <b class="c-form__border"></b>
                            </label>
                        </div>

                        <label class="c-form__toggle">Login/Signup<span class="c-form__toggleIcon"></span></label>
                    </form>
                </div>
            </div>
            { ["SENDINGOTP_SUCCESS", "SENDINGOTP_ERROR"].includes(authentication.status) &&
                        <div style={{textAlign: "center", color: "#fff", padding: "10%", cursor: "pointer"}}
                                onClick={this.forOtp}>
                                Resend OTP   
                        </div>
            }
            {
                <div style={{paddingLeft : "10%"}}>
                    {error}
                    {this.state.processing && "Loading..."}
                </div>
            }
        </div>
      
        )
    }
}

function mapStateToProps(state) {
    const { taskcreate, authentication } = state;
    return {
        taskcreate,
        authentication
    };
  }
  
export default withRouter(connect(mapStateToProps)(Login));