import React, { Component } from "react";
import { Layout, Link } from "..";
import appinfo from "../../clientsJSON";

export default class Contact extends Component {

    renderLink = (value, link) => {
        return (
            <Link
                link = {link}
            >
                <h5 style={{color: "#000", fontWeight: 400}}>{value}</h5>
            </Link>
        );
    }


    renderLayout = (icon, key) => {
        return(
            <Layout 
                    columns={2} 
                    extraClasses = { "center padding-40" }
                >
                    <span 
                        className={"material-icons"} 
                        style={{fontSize: 60}} 
                    > 
                        {icon} 
                    </span>
                    <>
                        { 
                            appinfo[key].map( i => {
                                return this.renderLink(
                                    i,
                                    (key === "mobile" ? "tel:" : "mailto:") + i
                                )
                            })
                        }
                    </>
            </Layout>
        )
    }


    render(){
    
        return (
            <div className="flex padding-20 wrap center" style = {{minHeight: "300px"}}>
                {this.renderLayout("phone", "mobile")}
                {this.renderLayout("email", "email")}                
            </div>
        );
    }
}