import React, { Component } from "react";

export default class videoLayout extends Component{
    render(){

        const {
            videoSource = "",
            videoWidth = "",
            videoHeight = "",
            videoStyle = {},
            videoContainerStyle = {},
            extraClasses = "no-padding",
            videoExtraClasses = "",
            videoId = "",
            autoPlay = true,
            controls = true
        } = this.props;

        return(
            <div 
                className={"videoContainerStyle "+extraClasses} 
                style={videoContainerStyle}
            >
                <video 
                    src={videoSource} 
                    width={videoWidth} 
                    height={videoHeight} 
                    style={videoStyle}
                    className={"videoStyle " + videoExtraClasses }
                    id = { videoId }
                    autoPlay = { autoPlay }
                    controls = { controls }
                />
            </div>
        )
    }
}