import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ALLBLOG from "../../component/blog/allblog";
import { Menu, Footer } from "../../component";
import appinfo from "../../clientsJSON";
import { TopLoader } from "../../component";
import { blogActions } from "../../actions";
import ReactPaginate from 'react-paginate';
import "./index.scss"

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      offset: false,
      totalpages: 0,
      processing: false,
    }
  }
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    let page = this.state.activePage;
    dispatch(blogActions.Getallblog(page));
  }


  rendernextpage = () => {
    const { dispatch } = this.props;
    let page = this.state.activePage
    dispatch(blogActions.Getallblog(page + 1))
    this.setState({ offset: false })
  }

  handlePageChange = pageNumber => {
    let pages = pageNumber.selected
    this.setState({ activePage: pages, offset: true })
  }

  componentWillReceiveProps(nxtProps) {
    const { blog } = this.props;
    let hasPropsChanged = blog.status !== nxtProps.blog.status;

    if (nxtProps.blog.status === "GET_ALLBLOG_SUCCESS") {
      let pages = nxtProps.blog.blogs.total_pages;
      this.setState({ totalpages: pages, processing: true })

    }
  }

  render() {
    document.title = "Blog |" + appinfo.name;
    const { blog } = this.props;

    return (
      <div>
        <Menu />
        {blog.status === "GET_ALLBLOG_REQUEST" &&
          <div>
            <TopLoader />
            <p style={{ textAlign: "center", margin: "100px" }}>Please be wait while collect the information</p>
          </div>}

        {blog.status === "GET_ALLBLOG_SUCCESS" && <div>
          <ALLBLOG content={blog.blogs.message} />
        </div>}

        {this.state.processing && blog.blogs.message.length !== 0 && <div className="pagination">
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            pageCount={this.state.totalpages}
            marginPagesDisplayed={0}
            pageRangeDisplayed={0}
            onPageChange={this.handlePageChange}
            disableInitialCallback={false}
          />
        </div>}

        {this.state.offset === true && this.rendernextpage()}
        {blog.status === "GET_ALLBLOG_ERROR" &&
          <div>
            <p style={{ textAlign: "center", margin: "100px" }}>No Blogs! We are yet to write one! Come back soon!</p>
          </div>}
        <Footer />
      </div>
    )
  }
}



function mapStateToProps(state) {
  const { blog } = state;
  return {
    blog
  }
}

export default withRouter(connect(mapStateToProps)(Blog))