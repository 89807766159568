import React, { Component } from "react";
import { 
    ContentLayout, 
    Layout,
    ReviewCard,
    ReviewCard2,
    Slider,
} from "..";

export default class ReviewSection extends Component{


    renderDetail = (contentProp = {}) => {

        const{ 
            containerProps = {},
            imageProps = {},
            logoProps = {},
            slider = false,
            template = 1
        } = this.props;

        let imageProp = { ...imageProps, imageSource: contentProp.imageSource };
        let logoProp = { ...logoProps, imageSource: contentProp.logo || "" };
        let containerProp = { ...containerProps, columns: 1 };

        switch(template) {
            case 2 : return (
                                <ReviewCard2
                                    containerProps={(slider ? containerProp : containerProps) || {}}
                                    imageProps =  {imageProp || {}}
                                    contentProps = {contentProp || {}}
                                />
                            );
            default : return(
                                <ReviewCard
                                    containerProps={(slider ? containerProp : containerProps) || {}}
                                    imageProps =  {imageProp || {}}
                                    contentProps = {contentProp || {}}
                                    logoProps = {logoProp || {}}
                                />
                            );
        }
    }

    render(){
        const {  
            containerProps = {},
            contentProps = [], 
            header = {}, 
            backgroundColor = "transparent",
            slider = false,
            sectionStyle = {},
            id = ""
        } = this.props;

        let noOfCards = containerProps.columns || 4 ;
        
        return(
        <Layout
            layoutStyle={{backgroundColor, ...sectionStyle }}
            extraClasses = "section no-radius"
        >
            <div className="review-section padding-70" id = { id }>
                <ContentLayout
                    title={header.title || ""}
                    description={header.description || ""}
                    titleStyle={header.titleStyle || {}}
                    descriptionStyle={header.descriptionStyle || {}}
                    subTitle={header.subTitle || ""}
                    subTitleStyle={header.subTitleStyle || {}}
                    contentStyle={header.contentStyle || {}}
                    extraClasses={header.extraClasses || ""}
                    tag = {{ title: "h2", subTitle: "h4" , ...header.tag }}
                />
                {
                    !slider &&
                    <div className="flex wrap">
                        { contentProps.map( content => <>{ this.renderDetail(content) }</>) }
                    </div>
                }
                {
                    slider &&
                    <Slider noOfCards = {noOfCards}>
                        { contentProps.map( content => <>{ this.renderDetail(content) }</>) }
                    </Slider>
                }
            </div>
        </Layout>
        );
    }
}