import React, { Component } from "react";
import {
    Layout,
    ContentLayout
} from "../";

export default class TableLayout extends Component {

    render() {
        const { 
            title = "",
            titleStyle = "",
            table = [],
            containerProps = {},
            tag = { title: "h3"}
        } = this.props;

        let td = {};
        if(table.length !== 0)
            table.forEach( i => {
                td = Object.assign({}, td, i);
            });
        let tableKeys = Object.keys(td);

        return (
                <Layout
                    extraClasses = { containerProps.extraClasses || {}}
                    layoutStyle = { containerProps.layoutStyle || {} }
                    columns = { containerProps.columns || 1 }
                >
                  <div >
                        <ContentLayout
                            title = { title || ""}
                            titleStyle = { titleStyle || {}}
                            tag = { tag || { title: "h3"}}
                        />
                        <table>
                            <thead>
                            <tr>
                                {
                                    tableKeys.map(j => {
                                        return <th>{j}</th>
                                    })
                                }
                            </tr>
                            </thead>
                            <tbody>
                            { 
                                table.map(k => {
                                    return (
                                        <tr>
                                            {
                                                tableKeys.map( j => {
                                                    return <td data-column={j}>
                                                           { k[j] || "-"}
                                                    </td>
                                                })
                                            }
                                        </tr>)
                                    })
                            }
                            </tbody>
                        </table>
                      
                  </div>
                </Layout>
        );
    }
}