import React, { Component } from "react";
import { ContentLayout, SingleCard, Layout } from "../";

export default class PartnerSection extends Component{

    renderLogo = (logo) => {

        const{ 
            containerProps = {},
            imageProps = {}
        } = this.props;

        let imageProp = { 
                          ...imageProps , 
                          imageSource: logo.imageSource || "", 
                          imageAlt: logo.imageAlt || ""
                        };

        return(
            <SingleCard
                imageProps={imageProp || {}}
                containerProps={containerProps || {}}
            />
       );
    }


    render(){

        const {  
                logos = [], 
                header = {}, 
                backgroundColor = "transparent" ,
                sectionStyle = {}
            } = this.props;

        return(

        <Layout
            layoutStyle={{backgroundColor, ...sectionStyle }}
            extraClasses = "no-radius"
        >
            <div className="container padding-70">
                <ContentLayout
                    title={header.title || ""}
                    description={header.description || ""}
                    titleStyle={header.titleStyle || {}}
                    descriptionStyle={header.descriptionStyle || {}}
                    subTitle={header.subTitle || ""}
                    subTitleStyle={header.subTitleStyle || {}}
                    contentStyle={header.contentStyle || {}}
                    extraClasses={header.extraClasses || ""}
                    tag = {{ title: "h2", subTitle: "h4" , ...header.tag }}
                />
                <div className="container flex wrap">
                    { 
                        logos.map( logo => <>{ this.renderLogo(logo) }</>)
                    }
                </div>
            </div>
        </Layout>
        );
    }
}