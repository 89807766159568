import React, { Component } from "react";

export default class ContentLayout extends Component{

    renderTag = (tag = "p", style = {}, sectionClass= "", content = "") => {
        switch(tag){
            case "h1": return <h1 style={style} className={sectionClass}>{content}</h1>;
            case "h2": return <h2 style={style} className={sectionClass}>{content}</h2>;
            case "h3": return <h3 style={style} className={sectionClass}>{content}</h3>;
            case "h4": return <h4 style={style} className={sectionClass}>{content}</h4>;
            case "h5": return <h5 style={style} className={sectionClass}>{content}</h5>;
            case "h6": return <h6 style={style} className={sectionClass}>{content}</h6>;
            default: return <p style={style} className={sectionClass}>{content}</p>;
        }
    }

    render() {
        const { title = "" , 
                description = "", 
                contentStyle = {},
                titleStyle = {}, 
                descriptionStyle = {},
                extraClasses = "",
                subTitle = "", 
                subTitleStyle = {}, 
                tag = { }
            } =  this.props;

        return (
            <div style={contentStyle} className={"contentContainerStyle padding "+extraClasses}>
                {   
                    title !== "" &&
                    this.renderTag(
                        tag.title || "h1", 
                        titleStyle, 
                        "titleStyle", 
                        title
                    )
                }
                { 
                    subTitle !== "" &&
                    this.renderTag(
                        tag.subTitle || "h4", 
                        subTitleStyle, 
                        "subTitleStyle", 
                        subTitle
                    )
                }
                { 
                    description !== "" &&
                    this.renderTag(
                        tag.description || "p", 
                        descriptionStyle, 
                        "descriptionStyle",
                        description
                    )
                }
            </div>
        );
    }
}