import React, { Component } from "react";
import { Button, ContentLayout, Link, SingleImageLayout,  VideoLayout, Layout } from "..";
import { utils } from "../../utils";

export default class AlternateLayout extends Component {
    render() {
        const {

            reverse = false ,
            imageProps = {},
            videoProps = {},
            header = {},
            contentProps = {},
            linkProps = {},
            buttonProps = {},
            backgroundColor = "transparent",
            sectionStyle = {},
            containerProps = {},
            extraClasses = ""
        } = this.props;

        let mobile = window.screen.width < 768;
        let contentPreExtraClasses = " padding-40 "; 
        let imageProp = {
            ...imageProps, 
            imageSource: contentProps.imageSource || imageProps.imageSource
        };
        let videoProp = {
            ...videoProps, 
            videoSource: contentProps.videoSource || videoProps.videoSource
        };

        return (

        <Layout
            layoutStyle={{backgroundColor, ...sectionStyle }}
            extraClasses = {extraClasses || ""}
        >
            <div className = "container">
                <ContentLayout
                    title={header.title || ""}
                    description={header.description || ""}
                    titleStyle={header.titleStyle || {}}
                    descriptionStyle={header.descriptionStyle || {}}
                    subTitle={header.subTitle || ""}
                    subTitleStyle={header.subTitleStyle || {}}
                    contentStyle={header.contentStyle || {}}
                    extraClasses={header.extraClasses || ""}
                    tag = {{ title: "h2", subTitle: "h4" , ...header.tag }}
                />
                <Layout
                    columns = {0}
                    extraClasses={"services_detail_row center " + containerProps.extraClasses}
                    layoutStyle={{ 
                        flexDirection: mobile ? "column" : 
                        ( !reverse ? "row" : "row-reverse") ,
                        ...containerProps.layoutStyle 
                    }}
                >
                    {
                        utils.isNotEmpty(imageProp.imageSource) &&
                        <Layout
                            columns = {2}
                        >
                            <SingleImageLayout 
                                imageSource={imageProp.imageSource || ""} 
                                imageStyle={imageProp.imageStyle || {}} 
                                imageWidth={imageProp.imageWidth || "100%"} 
                                imageHeight={imageProp.imageHeight || "100%"}
                                imageAlt={imageProp.imageAlt || ""}
                                imageContainerStyle = {imageProp.imageContainerStyle || {}}
                                extraClasses = { imageProp.extraClasses + " center"}
                            />
                        </Layout>
                    }
                    {
                        utils.isNotEmpty(videoProp.videoSource) &&
                        <Layout
                            columns = {2}
                        >
                            <VideoLayout 
                                videoSource={videoProp.videoSource || ""} 
                                videoStyle={videoProp.videoStyle || {}} 
                                videoWidth={videoProp.videoWidth || "100%"} 
                                videoHeight={videoProp.videoHeight || "100%"}
                                videoContainerStyle = {videoProp.videoContainerStyle || {}}
                                autoPlay = { videoProp.autoPlay || true }
                                controls = { videoProp.controls || true }
                                extraClasses = { videoProp.extraClasses + " center"}
                            />
                        </Layout>
                    }
                    <div className = "layout-column-2" style={{margin: "0px 40px"}}>
                        <ContentLayout
                            title={contentProps.title || ""}
                            description={contentProps.description || ""}
                            titleStyle={contentProps.titleStyle || {}}
                            subTitle={contentProps.subTitle || ""}
                            subTitleStyle={contentProps.subTitleStyle || {}}
                            descriptionStyle={contentProps.descriptionStyle || {}}
                            contentStyle={contentProps.contentStyle || {}}
                            extraClasses={
                                contentProps.extraClasses ? 
                                contentPreExtraClasses + contentProps.extraClasses  : 
                                contentPreExtraClasses
                            }
                            tag = {{ title: "h2", subTitle: "h4" , ...contentProps.tag }}
                        />
                        { 
                            !["", undefined, null].includes(linkProps.link) &&
                            !["", undefined, null].includes(linkProps.linkText) &&
                            <Link
                                linkStyle = {linkProps.linkStyle || {}}
                                link = {linkProps.link || ""}
                                linkText = { linkProps.linkText || ""}
                                showIcon = {linkProps.showIcon || false }
                                iconName = {linkProps.iconName || ""}
                                iconReverse = { linkProps.iconReverse || false } 
                                iconStyle = { linkProps.iconStyle || {}}
                                newTab = { linkProps.newTab || false }
                                extraClasses = { "flex center padding-20 " + linkProps.extraClasses }
                            />
                        }
                        { 
                            !["", undefined, null].includes(buttonProps.buttonLink)  &&
                            !["", undefined, null].includes(buttonProps.buttonText)  &&
                            <Button
                                buttonText = {buttonProps.buttonText || ""} 
                                buttonStyle = {buttonProps.buttonStyle || {}}
                                extraClasses = {buttonProps.extraClasses || ""}
                                showIcon = {buttonProps.showIcon || false }
                                iconName = {buttonProps.iconName || ""}
                                iconReverse = { buttonProps.iconReverse || false } 
                                iconStyle = { buttonProps.iconStyle || {}}
                                buttonLink = {buttonProps.buttonLink || ""}
                                newTab = {buttonProps.newTab || false }
                            />
                        }
                    </div>
                </Layout>
            </div>
        </Layout>
        );
    }
}