import React, { Component } from "react";

export default class ReviewContentLayout extends Component{
    render() {
        const { 
                review = "",
                name = "",
                designation = "", 
                contentStyle = {},
                reviewStyle = {},
                extraClasses = "",
                reviewDesignationStyle = {},
                reviewNameStyle = {}
            } =  this.props;

        return (
            <div style={contentStyle} className={"contentContainerStyle padding "+extraClasses}>
                <p style={reviewStyle} className="reviewStyle">
                    {review}
                </p>
                <h6 style={reviewNameStyle} className="reviewNameStyle">
                    <b> { name }</b>
                </h6>
                <h6 style={reviewDesignationStyle} className="reviewDesignationStyle">
                    { designation }
                </h6>
            </div>
        );
    }
}