import React, { Component } from "react";
import appinfo from "../../clientsJSON";

export default class Link extends Component{
    constructor(props){
        super(props);
        this.state = {
            hover : false
        }
    }

    toggleLink = () => {
        this.setState( 
            (prevState) => ({ 
                hover: !prevState.hover 
            })
        );
    }

    render() {
        const { 
            linkStyle = {}, 
            link = "", 
            linkText = "", 
            showIcon = false, 
            iconName = "",
            iconReverse = false,
            newTab = false ,
            extraClasses = "",
            iconExtraClasses = "",
            iconStyle = {},
            borderBottom = false
        } = this.props;

        let btn =  appinfo.button_color;

        return(
            <a  href={link} 
                style={{ 
                    color:  btn ,
                    fontWeight: 500, 
                    // opacity: !this.state.hover ? 1 : 0.8, 
                    // borderBottom: !borderBottom ? "none" : this.state.hover && "2px solid " + btn,
                    ...linkStyle 
                }}
                onMouseEnter = { this.toggleLink }
                onMouseLeave = { this.toggleLink }
                target={ newTab ? "_blank" : "_self" }
                className = { extraClasses }
            >
                { linkText || this.props.children }
                {
                    showIcon &&
                        <i className = { iconExtraClasses || "material-icons"} 
                            style={{
                                verticalAlign: "sub", 
                                padding: "0px 10px",
                                height: "16px", 
                                fontSize: "18px",
                                paddingLeft : iconReverse ? "0px" : "10px",
                                paddingRight: !iconReverse ? "0px" :"10px",
                                float: iconReverse ? "left" : "right",
                                ...iconStyle 
                            }}>
                            {iconName}
                        </i>
                }
            </a>
        );
    }
}