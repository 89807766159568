import React, { Component } from "react";
import { ContentLayout, Layout, Link } from "..";

export default class Copyright extends Component {
    render () {
        const {
            textColor = "",
            linkColor = "",
            backgroundColor = "",
            enable = true
        } = this.props;

        let textStyle = {
            marginBlockEnd: "0px", 
            marginBlockStart: "0px",
            textAlign: "right",
            fontSize : "12px" 
        };
        if(textColor !== "") textStyle["color"] = textColor;

        return (
            <Layout 
                columns = {1}
                layoutStyle = {{
                    display: !enable ? "none" : "",
                    height : "50px",
                    borderRadius: "0px",
                    backgroundColor : backgroundColor || "transparent"
                }}
                extraClasses = {"copyright"} 
            >
                <ContentLayout
                    description = {"Powered by"}
                    descriptionStyle = { textStyle }
                    extraClasses = {"no-margin"}
                />
                <Link
                    linkText = { "Moonproduct.in"}
                    link = {"https://moonproduct.in"}
                    linkStyle = {{ 
                        fontSize : "12px",
                        color: ( linkColor || "#4053AE" ) 
                    }}
                    newTab = { true }
                    showIcon = { true }
                    iconReverse = { true }
                    iconName = {"brightness_2"}
                    iconStyle = {{ 
                        transform : "rotate(20deg)", 
                        padding: "5px 3px 0px 0px" ,
                        fontSize: "14px"
                    }}
                />
            </Layout>
        );
    }
}