import React,{Component} from "react";
import appinfo from "../../clientsJSON";
import "../../index.css";
import {
    Accordion,
    Container,
    DefaultSection,
    AlternateLayout,
    ContentLayout,
    PartnerSection
} from "../../component";

class Services extends Component {
    constructor(props){
        super(props);
        this.state={}
    }
 
   
    render(){
        document.title = "Services | " + appinfo.name;

        return (
            <Container >
                {
                    appinfo.service_sections.map(
                        ( service, index ) => {
                            let contentProp = {
                                ...service.content.contentProps,
                                title : service.content.title,
                                description : service.content.description,
                                imageSource : service.content.image,
                            }
                            let linkProp = {
                                ...service.content.linkProps ,
                                link: `/${appinfo.services.title}/${service.content.title.replace(" ","-").toLowerCase()}` || ""
                            }

                            let buttonProp = {
                                ...service.content.buttonProps ,
                                buttonLink: `/${appinfo.services.title}/${service.content.title.replace(" ","-").toLowerCase()}` || ""
                            }

                            switch(service.cardType){
                                case "products" : 
                                        return <DefaultSection
                                                    containerProps = {service.content.containerProps || {}}
                                                    imageProps = {service.content.imageProps || {}}
                                                    linkProps = {linkProp || {}}
                                                    contentProps = { contentProp || []}
                                                    header = { service.content.header || {}}
                                                    backgroundColor = {service.content.backgroundColor || "transparent"}
                                                    buttonProps= {service.content.buttonProps || {}} 
                                                    slider = {service.content.slider || false}
                                                    sectionStyle = {service.content.sectionStyle || {}}
                                                    id = { service.content.id || "products"}
                                                />;
                                case "partners" : return <PartnerSection
                                                    containerProps = { service.content.containerProps || {}}
                                                    imageProps = { service.content.imageProps || {}}
                                                    logos = {service.content.logos || []} 
                                                    header = {service.content.header || {}}
                                                    backgroundColor = { service.content.backgroundColor || "transparent" }
                                                    sectionStyle = { service.content.sectionStyle || {}}
                                                    id = { service.content.id || "partners"}
                                                />;

                                case "alternate" : return <AlternateLayout
                                                    reverse = {service.content.reverse || index % 2 === 0 ? true : false}
                                                    header={service.content.header || {}}
                                                    imageProps = {service.content.imageProps || {}}
                                                    videoProps={service.content.videoProps || {}}
                                                    contentProps = {contentProp || {}}
                                                    linkProps = {linkProp || {}}
                                                    buttonProps = {buttonProp || {}}
                                                    backgroundColor = {service.content.backgroundColor || "transparent"}
                                                    sectionStyle = {service.content.sectionStyle || {}}
                                                    id = { service.content.id || "alternate"}
                                                />;
                                    
                                case "content" : return <ContentLayout
                                                    title = {contentProp.title || ""}
                                                    description = { contentProp.description || ""} 
                                                    contentStyle = { { ...contentProp.contentStyle , paddingTop: "70px"}}
                                                    titleStyle = {contentProp.titleStyle || {}} 
                                                    descriptionStyle = {contentProp.descriptionStyle || {}}
                                                    subTitle={contentProp.subTitle || ""}
                                                    subTitleStyle={contentProp.subTitleStyle || {}}
                                                    extraClasses = {contentProp.extraClasses || ""}
                                                    tag = {contentProp.tag || {}}
                                                    id = { service.content.id || "content"}
                                                />;
                                case "accordion" : return <Accordion 
                                                    list = {service.content} 
                                                    sectionStyle = {service.sectionStyle || {} }
                                                />;
                                default : return <div></div>;
                            }
                        }
                    )
                }
            </Container>

        )
    }    
}
export default Services;