import React, { Component } from "react";
import { 
    ContentLayout, 
    Layout, 
    IconLayout, 
    Link
} from "../";

export default class IconSingleCard extends Component {
    
    render(){
        const {
            containerProps = {},
            iconProps = {},
            linkProps = {},
            contentProps = {}
        } =  this.props;
        return(
            <Layout 
                columns={containerProps.columns || 3} 
                extraClasses={containerProps.extraClasses || "center space"} 
                layoutStyle={containerProps.layoutStyle || {}}
            >
                { !["", undefined, null].includes(iconProps.iconName) &&
                    <IconLayout
                        iconName = { iconProps.iconName || ""}
                        iconStyle = {{ fontSize: "60px" ,...iconProps.iconStyle }}
                        outlined = { iconProps.outlined || false }
                    />
                }
                { 
                    (!["", undefined, null].includes(contentProps.description) ||
                    !["", undefined, null].includes(contentProps.title) ) &&
                    <ContentLayout
                        title={contentProps.title || ""}
                        description={contentProps.description || ""}
                        titleStyle={contentProps.titleStyle || {}}
                        descriptionStyle={contentProps.descriptionStyle || {}}
                        subTitle={contentProps.subTitle || ""}
                        subTitleStyle={contentProps.subTitleStyle || {}}
                        contentStyle={contentProps.contentStyle || {}}
                        extraClasses={contentProps.extraClasses || ""}
                        tag = {{ title: "h2", subTitle: "h4" , ...contentProps.tag }}
                    />
                }
                { 
                    !["", undefined, null].includes(linkProps.link) &&
                    <Link
                        linkStyle = {linkProps.linkStyle || {}}
                        link = {linkProps.link || ""}
                        linkText = { linkProps.linkText || ""}
                        showIcon = {linkProps.showIcon || false }
                        iconName = {linkProps.iconName || ""}
                        iconReverse = { linkProps.iconReverse || false } 
                        iconStyle = { linkProps.iconStyle || {}}
                        newTab = { linkProps.newTab || false }
                        extraClasses = { "flex center padding-20 " + linkProps.extraClasses }
                    />
                }
            </Layout>
        );
    }
}