import React,{Component} from 'react';
import "./index.scss"
import appinfo from '../../clientsJSON';

class ALLBLOG extends Component{

    render(){
      const {content={}}=this.props;
     return(
       <div>
       {content.map((i,index)=>{
          let date = new Date( Date.parse(i.modified)) 
        return( 
       <div className="mpallblog">
        {index%2===0 &&
         <div className="mpblog-card alt">
          <div className="mpmeta">
            <div className="mpallblogphoto" style={{backgroundImage:`url(${i.image})`}}></div>
            <ul className="mpallblogdetails">
              <li className="mpallblogauthor">{appinfo.name}</li>
              <li className="mpallblogdate">{date.toLocaleString()}</li>
            </ul>
            </div>
            <div className="mpallblogdescription">
              <h3 style={{color:appinfo.primaryTextColor}}>{i.title}</h3>
              <p>{i.description}</p>
              <p className="read-more">
                <a  href={`/blog/`+i.url.replace(" ","-")}  style={{textDecoration:"none"}}>Read More</a>
              </p>
            </div>
        </div>}
        {index%2!==0 &&<div className="mpblog-card">
          <div className="mpmeta">
            <div className="mpallblogphoto" style={{backgroundImage:`url(${i.image})`}}></div>
            <ul className="mpallblogdetails">
              <li className="mpallblogauthor">{appinfo.name}</li>
              <li className="mpallblogdate">{date.toLocaleString()}</li>
            </ul>
            </div>
            <div className="mpallblogdescription">
              <h3 style={{color:appinfo.primaryTextColor}}>{i.title}</h3>
              <p>{i.description}</p>
              <p className="read-more">
                <a  href={`/blog/`+i.url.replace(" ","-")}   style={{textDecoration:"none",cursor:"pointer"}}>Read More</a>
              </p>
            </div>
        </div>}
        </div>)})}
        {content.length===0 &&
        <div style={{minHeight: "calc(100vh - 300px)" }}>
          <p style={{textShadow:10,textAlign:"center",marginTop:"120px", marginBottom:"120px"}}>
            No Blogs Posted yet. Stay tuned!!
          </p>
        </div>}
        </div>
     )
    }
    
}

export default ALLBLOG;