import React, { Component } from "react";
import appinfo from "../../clientsJSON";

class ServiceCard extends Component {
    render(){
        const { content={} , index=0 } =this.props;

        let style =  {};
        let includes = window.screen.width < 768;

        if(index %2 !== 0) style["backgroundColor"] = "white";

        return (
            <div style={style}>
                <div className="services_detail_row container" 
                    style={{flexDirection: includes ? "column" : ( index%2 === 0 ? "row" : "row-reverse") }}
                >
                    <div className="split2">
                        <div className={index%2 === 0 ? "circle" : "circle_reverse" }>
                            <img src={content.image} alt={content.title} style={{borderRadius:16,margin:"16px"}} />
                        </div>
                    </div>
                    <div className="split2" style={{flexDirection:"column",padding:"0px 32px",textAlign:"left"}}>
                        <h2>{content.title}</h2>
                        <p>{content.description}</p> 
                        {content.id !== "-1" &&
                    <div className="Container">
                        <a href={`/`+appinfo.services.title+`/`+content.title.replace(" ","-")} alt={content.title} style={{textDecoration:"none",color:appinfo.button_color}}> <p style={{textAlign: "end",color:appinfo.button_color}}>View more <i class="material-icons" style={{verticalAlign:"text-top",height:16}}>arrow_forward</i></p></a>                     </div> 
                }
                    </div>              
                </div>
                {/* { content.id !== "-1" &&
                    <div className="container">
                        <a href={`/`+appinfo.services.title+`/`+content.title.replace(" ","-")} alt={content.title}>
                            <p style={{textAlign: "end"}}>View more</p>
                        </a>
                        <p style={{textAlign: "end"}}>Get Brouchre</p>
                    </div> 
                } */}
            </div>
        );
    }
}

export default ServiceCard;