import {authenticationConstants} from "../constants";
import {authenticationService} from "../services";

export const authenticationActions ={
    isAvailable,
    login,
    logout,
    sendOtp,
    signup
};


function  isAvailable(payload){
    return dispatch =>{
        dispatch(request());
        authenticationService.isAvailable(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
    return {type:authenticationConstants.ISAVAILABLE_REQUEST};
    }
    function success(user){
     return {type:authenticationConstants.ISAVAILABLE_SUCCESS,user};
    }
    function failure(error){
        return {type:authenticationConstants.ISAVAILABLE_ERROR,error};
    }
}



function  login(payload){
    return dispatch =>{
        dispatch(request());
        authenticationService.login(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
    return {type:authenticationConstants.LOGIN_REQUEST};
    }
    function success(user){
     return {type:authenticationConstants.LOGIN_SUCCESS,user};
    }
    function failure(error){
        return {type:authenticationConstants.LOGIN_ERROR,error};
    }
}


function logout() {

    authenticationService.logout();
    return { type: authenticationConstants.LOGOUT };
  }

function sendOtp(payload){
    return dispatch =>{
        dispatch(request());
        authenticationService.sendOtp(payload).then(
            login =>{ dispatch(success(login))},
            error =>{ dispatch(failure(error))}
        );
    };
    function request(){
        return{type: authenticationConstants.SENDINGOTP_REQUEST};
    }
    function success(otp){
        return {type:authenticationConstants.SENDINGOTP_SUCCESS,otp};
    }
    function failure(error){
        return {type:authenticationConstants.SENDINGOTP_ERROR,error};
    }
}

  function signup(payload){
    return dispatch =>{
        dispatch(request());
        authenticationService.signup(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return {type:authenticationConstants.SIGNUP_REQUEST};
    }
    function success(user){
        return {type:authenticationConstants.SIGNUP_SUCCESS,user};
    }
    function failure(error){
        return {type:authenticationConstants.SIGNUP_ERROR,error};
    }
}