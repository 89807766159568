import React from "react";
import TopBarProgress from "react-topbar-progress-indicator";


/***Configuration area for top bar loader***/ 
TopBarProgress.config({
    barThickness: 4,
    shadowBlur: 10,
   barColors: {
    "0": "#052656",
    "0.5": "#052656",
    "1.0": "#052656",
  },
    // shadowColor:"#00f"
  });

export default class TopLoader extends React.Component{
      render(){
          return(
              <div><TopBarProgress /></div>
          )
      }
  }