import React, { Component } from "react";
import { 
    ReviewContentLayout,
    Layout, 
    SingleImageLayout, 
} from "..";

export default class ReviewCard extends Component {
    
    render(){
        const {
            containerProps = {},
            imageProps = {},
            logoProps = {},
            contentProps = {}
        } =  this.props;

        return(
            <Layout 
                columns={containerProps.columns || 3} 
                extraClasses={containerProps.extraClasses || "center space"} 
                layoutStyle={containerProps.layoutStyle || {}}
            >
                { !["", undefined, null].includes(imageProps.imageSource) &&
                    <SingleImageLayout 
                        imageSource={imageProps.imageSource || ""} 
                        imageStyle={imageProps.imageStyle || {borderRadius: "50%", marginTop: "20px", marginLeft: "20px"}} 
                        imageWidth={imageProps.imageWidth || "75px"} 
                        imageHeight={imageProps.imageHeight || "75px"}
                        imageAlt={imageProps.imageAlt || ""}
                        imageContainerStyle = {imageProps.imageContainerStyle || {}}
                        extraClasses = {imageProps.extraClasses  || ""}
                    />
                }
                { 
                    !["", undefined, null].includes(contentProps.review)  &&
                    <ReviewContentLayout
                        review={contentProps.review || ""}
                        reviewStyle={contentProps.reviewStyle || {}}
                        name={contentProps.name || ""}
                        reviewNameStyle={contentProps.reviewNameStyle || {}}
                        designation={contentProps.designation || ""}
                        reviewDesignationStyle={contentProps.reviewDesignationStyle || {}}
                        contentStyle={contentProps.contentStyle || {}}
                        extraClasses={contentProps.extraClasses || ""}
                    />
                }
                { !["", undefined, null].includes(logoProps.imageSource) &&
                    <SingleImageLayout 
                        imageSource={logoProps.imageSource || ""} 
                        imageStyle={logoProps.imageStyle || {marginLeft: "20px"}} 
                        imageWidth={logoProps.imageWidth || "50%"} 
                        imageHeight={logoProps.imageHeight || "100%"}
                        imageAlt={logoProps.imageAlt || ""}
                        imageContainerStyle = {logoProps.imageContainerStyle || {}}
                        extraClasses = {logoProps.extraClasses   || ""}
                    />
                }
            </Layout>
        );
    }
}