import React, { Component } from "react";
import appinfo from "../../clientsJSON";
// import "./index.css";
import Menu from "../../component/Menu";
import Footer from "../../component/footer";
import { 
    Accordion,
    ContentLayout, 
    DefaultSection, 
    PartnerSection, 
    AlternateLayout 
} from "../../component";



class About extends Component {
    render() {
        document.title = "About " + appinfo.name;
        return (
            <div>
                <Menu />
                {/* <div className="mp-container" style={{ textAlign: "center", padding: 50 }}>
                    <h1 style={{ textTransform: "uppercase" }}>About {appinfo.name}</h1>
                    <p style={{ textAlign: "justify" }}>{appinfo.about.description}</p>
                    <h2>{appinfo.about.cta}</h2>
                </div> */}
                {
                    appinfo.about_sections.map( ( about, index ) =>    {
                        switch(about.cardType){
                           
                            case "products" : return <DefaultSection
                                                        containerProps = {about.content.containerProps || {}}
                                                        imageProps = {about.content.imageProps || {}}
                                                        linkProps = {about.content.linkProps || {}}
                                                        contentProps = { about.content.contentProps || []}
                                                        header = { about.content.header || {}}
                                                        backgroundColor = {about.content.backgroundColor || "transparent"}
                                                        buttonProps= {about.content.buttonProps || {}} 
                                                        slider = {about.content.slider || false}
                                                        sectionStyle = {about.content.sectionStyle || {}}
                                                        id = { about.content.id || "products"}
                                                    />;
                            case "partners" : return <PartnerSection
                                                        containerProps = { about.content.containerProps || {}}
                                                        imageProps = { about.content.imageProps || {}}
                                                        logos = {about.content.logos || []} 
                                                        header = {about.content.header || {}}
                                                        backgroundColor = { about.content.backgroundColor || "transparent" }
                                                        sectionStyle = { about.content.sectionStyle || {}}
                                                        id = { about.content.id || "partners"}
                                                    />;

                            case "alternate" : return <AlternateLayout
                                                        reverse = {about.content.reverse || false}
                                                        header={about.content.header || {}}
                                                        imageProps = {about.content.imageProps || {}}
                                                        videoProps={about.content.videoProps || {}}
                                                        contentProps = {about.content.contentProps || {}}
                                                        linkProps = {about.content.linkProps || {}}
                                                        buttonProps = {about.content.buttonProps || {}}
                                                        backgroundColor = {about.content.backgroundColor || "transparent"}
                                                        sectionStyle = {about.content.sectionStyle || {}}
                                                        id = { about.content.id || "alternate"}
                                                    />;
                                
                            case "content" : return <ContentLayout
                                                        title = {about.content.contentProps.title || ""}
                                                        description = { about.content.contentProps.description || ""} 
                                                        contentStyle = { { ...about.content.contentProps.contentStyle , minHeight: "2px"}}
                                                        titleStyle = {about.content.contentProps.titleStyle || {}} 
                                                        descriptionStyle = {about.content.contentProps.descriptionStyle || {}}
                                                        subTitle={about.content.contentProps.subTitle || ""}
                                                        subTitleStyle={about.content.contentProps.subTitleStyle || {}}
                                                        extraClasses = {about.content.contentProps.extraClasses || "container padding-70"}
                                                        tag = {{ title: "h2", subTitle: "h4" , ...about.content.contentProps.tag}}
                                                        id = { about.content.id || "content"}
                                                    />;
                            case "accordion" : return <Accordion 
                                            
                                                        list = {about.content.list || {}} 
                                                        sectionStyle = {about.content.sectionStyle || {} }
                                                    />;
                            default : return <div></div>;
                        }
                    })
                }
                <Footer />
            </div>
        )
    }
}
export default About;