import React, { Component } from "react";

export default class IconLayout extends Component {
    render () {
        const { 
            iconStyle = {},
            iconName = "",
            outlined = false,
            extraClasses = "center"
        } = this.props;
        return(
            <div className = { extraClasses }>
                <span className= { outlined ? "material-icons-outlined" : "material-icons" }  
                    style={iconStyle}>
                    {iconName}
                </span>
            </div>
        );
    }
}