import React, {Component} from "react";

export default class SingleImageLayout extends Component{
    render(){

        const {
            imageSource = "",
            imageAlt="",
            imageWidth = "",
            imageHeight = "",
            imageStyle = {},
            imageContainerStyle = {},
            extraClasses = "no-padding",
            imageExtraClasses = "",
            imageId = "",
            onClick = () => null
        } = this.props;

        return(
            <div 
                className={"imageContainerStyle "+extraClasses} 
                style={imageContainerStyle}
            >
                <img 
                    src={imageSource} 
                    alt={imageAlt} 
                    width={imageWidth} 
                    height={imageHeight} 
                    style={imageStyle}
                    className={"imageStyle " + imageExtraClasses }
                    imageId = { imageId }
                    onClick  = { onClick }
                />
            </div>
        )
    }
}