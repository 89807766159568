import app from "../app.json";

const clientFiles = {
    Redbug: require('./redbug.json'),
    IshaFencing: require('./fencing.json'),
    247:require('./247.json'),
    Covid19:require('./covid19.json'),
    Testing: require('./testing.json'),
    Leads:require('./leads1.json'),
    Designs:require('./designs.json'),
    TrueConsultancy:require('./trueconsultancy.json'),
    tcv2: require('./tcv2.json'),
    DimpleMJ:require('./dimplemj.json')
};

const appinfo  = clientFiles[app.jsonFile];
export default appinfo;